import React from 'react';
import { getPublicPath } from '../utils/pathUtils';

const VideoPreview = ({ video }) => {
    if (!video) {
        return <p>No media available</p>;
    }

    //  const isImage = video.fileType && video.fileType.startsWith('image');
    const isImage = video.fileType?.startsWith('image');
    const isVideo = video.fileType?.startsWith('video');

    const previewPath = getPublicPath(video.localPath || video.localPath || '');

    return (
        <div style={{ maxWidth: '200px', margin: '10px auto' }}>
            {isImage && (
                <img
                    src={previewPath}
                    alt={video.originalFileName}
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                />
            )}
            {isVideo && (
        <video controls
        autoPlay
        style={{ width: '100%', maxWidth: '300px', height: 'auto' }} // Задава максимална ширина

        >
          <source src={previewPath} type={video.fileType || 'video/mp4'} />
          Your browser does not support the video tag.
        </video>
      )}
            {!isImage && !isVideo && <p>Unsupported file type</p>}
        </div>
    );
};

//video.fileType
export default VideoPreview;
