import React from 'react';

const FilteredCampaigns = ({ campaigns = [] }) => {
    console.log('FilteredCampaigns:', campaigns); // Преместено извън JSX
  
    return (
      <div>
        <h3>Filtered Campaigns</h3>
        {campaigns.length ? (
          <ul>
            {campaigns.map((campaign, index) => (
              <li key={index}>
                <strong>{campaign.campaignName || 'Unnamed Campaign'}</strong>
              </li>
            ))}
          </ul>
        ) : (
          <p>No campaigns available.</p>
        )}
      </div>
    );
  };

export default FilteredCampaigns;
