import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPmiDevices, fetchUniqueValues, fetchUsers, addContact, updateDevice, createDevice } from '../services/api';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Button,
  Pagination
} from '@mui/material';
import DeviceFormDialog from '../components/DeviceFormDialog';
import { CheckCircle, Cancel } from '@mui/icons-material'; // Импортирайте иконите
import DeviceFilters from '../components/pmiDeviceFilters';
import exportToExcel from '../utils/exportToExcel';


const PmiDevicesTable = () => {
  const { id } = useParams(); // Извличане на ID от URL
  const navigate = useNavigate();

  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('create'); // 'create' or 'edit'
  const [uniqueValues, setUniqueValues] = useState({});
  const [users, setUsers] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [filters, setFilters] = useState({
    deviceName: '',
    isRegistered: '',
    tradeSegment: [],
    kaName: [],
    posType: [],
    placementNew: [],
    manned: [],
    tire: [],
    screenType: [],
  });

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage, setDevicesPerPage] = useState(100);
  const [totalDevices, setTotalDevices] = useState(0);
  const [filteredDevicesOr, setFilteredDevicesOr] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPmiDevices();
        setDevices(data);

        const fields = ['tradeSegment', 'posTypeNew', 'kaName', 'manned', 'screenType', 'city', 'region', 'orientation', 'screenType', 'outputResolution', 'connectivity', 'splitter', 'seasonality', 'isActive', 'tire', 'placementNew'];
        const uniqueData = await Promise.all(fields.map((field) => fetchUniqueValues(field)));
        const uniqueObj = fields.reduce((acc, field, index) => {
          acc[field] = uniqueData[index].data.map((item) => item._id);
          return acc;
        }, {});
        setUniqueValues(uniqueObj);

        const userData = await fetchUsers();
        setUsers(userData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (devices.length > 0) {
      setFilteredDevices(devices);
    }
  }, [devices]); // Следи промените в `devices`

  //Pagination

  useEffect(() => {
    const totalPages = Math.ceil(filteredDevices.length / devicesPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1);
    }
  }, [filteredDevices, devicesPerPage]);

  const paginatedDevices = filteredDevices.slice(
    (currentPage - 1) * devicesPerPage,
    currentPage * devicesPerPage
  );

  useEffect(() => {
    if (id) {
      // Намерете устройството по ID и отворете формата
      const deviceToEdit = devices.find((device) => device._id === id);
      if (deviceToEdit) {
        handleOpenDialog('edit', deviceToEdit);
      }
    }
  }, [id, devices]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleDevicesPerPageChange = (event) => {
    setDevicesPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Рестартиране на текущата страница
  };

  const handleFilterChange = (filters) => {
    console.log("Filters applied:", filters); // Лог на текущите филтри
    const filtered = devices.filter((device) => {
      //console.log("Device being checked:", device); // Лог за устройството, което се проверява
      let logs = { device };

      const matchesName =
        !filters.deviceName ||
        device.name?.toLowerCase().includes(filters.deviceName.toLowerCase());
      //    console.log(`Name Match (${device.name}):`, matchesName);

      const matchesRegistration =
        filters.isRegistered === '' ||
        (filters.isRegistered === 'true' && device.serialNumber) ||
        (filters.isRegistered === 'false' && !device.serialNumber);
      //  console.log(`Registration Match (${device.serialNumber}):`, matchesRegistration);

      // Проверка за tradeSegment
      const matchesTradeSegment =
        filters.tradeSegment.length === 0 || filters.tradeSegment.includes(device.tradeSegment);
      // console.log(`Trade Segment Match (${device.tradeSegment}):`, matchesTradeSegment);
      logs.matchesTradeSegment = matchesTradeSegment;

      // Проверка за kaName само ако "KEY ACCOUNT" е избран в tradeSegment
      const matchesKaName =
        filters.tradeSegment.includes("KEY ACCOUNT") && device.tradeSegment === "KEY ACCOUNT"
          ? filters.kaName.length === 0 || filters.kaName.includes(device.kaName)
          : true;
      //     console.log(`KA Name Match (${device.kaName}):`, matchesKaName);
      logs.matchesKaName = matchesKaName;
      /*
        const matchesTradeSegment =
        filters.tradeSegment.length === 0 || filters.tradeSegment.includes(device.tradeSegment);
      
        const matchesKaName =
        filters.kaName.length === 0 || filters.kaName.includes(device.kaName);
      */
      const matchesOr = matchesTradeSegment || matchesKaName;
      const matchesPosType =
        filters.posType.length === 0 || filters.posType.includes(device.posTypeNew);
      const matchesplacementNew =
        filters.placementNew.length === 0 || filters.placementNew.includes(device.placementNew);
      const matchesmanned =
        filters.manned.length === 0 || filters.manned.includes(device.manned);
      const matchestire =
        filters.tire.length === 0 || filters.tire.includes(device.tire);
      const matchesscreenType =
        filters.screenType.length === 0 || filters.screenType.includes(device.screenType);

      const result = (
        matchesTradeSegment &&
        matchesKaName &&
        //matchesOr &&
        matchesName &&
        matchesRegistration &&
        matchesPosType &&
        matchesplacementNew &&
        matchesmanned &&
        matchestire &&
        matchesscreenType
      );
      if (!result && device.tradeSegment === "HORECA") {
        console.log("Filtered out device:", logs);
      }
      return result;
    });
    console.log("Filtered Devices:", filtered); // Лог за резултата след филтрирането

    setFilteredDevices(filtered);
  };


  const handleOpenDialog = (type, device = null) => {
    setDialogType(type);
    setSelectedDevice(device);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedDevice(null);
    navigate('/pmi-devices'); // Връщане към основния списък
  };
  useEffect(() => {
    handleFilterChange(filters);
  }, [filters, devices]); // Следи промени в filters или devices

  const handleDeviceSave = async (device) => {
    try {
      if (dialogType === 'create') {
        const createdDevice = await createDevice(device);
        setDevices((prev) => [...prev, createdDevice.device]);
      } else if (dialogType === 'edit') {
        const updatedDevice = await updateDevice(device._id, device);
        setDevices((prev) =>
          prev.map((d) => (d._id === updatedDevice._id ? updatedDevice : d))
        );
      }
      handleFilterChange(filters); // Приложете текущите филтри след обновяване

      handleDialogClose();
    } catch (err) {
      console.error('Error saving device:', err);
      alert('Failed to save device.');
    }
  };

  const exportFilteredDevices = () => {
    if (filteredDevices.length === 0) {
      alert('No devices to export!');
      return;
    }

    // Форматирайте данните за експорт
    const exportData = filteredDevices.map((device) => ({
      Name: {
        t: 's', // Тип string
        v: device.name || '',
        l: { Target: `https://on-labs.eu/pmi-devices/edit/${device._id}` }, // Линк към страницата за редакция
        s: { font: { color: { rgb: "0000FF" }, underline: true } }, // Стил: син текст с подчертаване

      },
      "POS Name": device.posName || '',
      "Trade Segment": device.tradeSegment || '',
      "KA Name": device.kaName || '',
      "POS Type": device.posTypeNew || '',
      Placement: device.placementNew || '',
      Manned: device.manned || '',
      Tire: device.tire || '',
      "Screen Type": device.screenType || '',
      "Orientation": device.orientation || '',
      "Display Size": device.diagonal || '',
      City: device.city || '',
      Region: device.region || '',
      Address: device.address || '',
      "Mario Address": device.macAddress || '',
      "Serial Number": device.serialNumber || '',
      "Is Registered": device.serialNumber ? 'Yes' : 'No',
      "TA Actual": device.talActual || '',
      "TAL Actual": device.taActual || '',
      "Connectivity": device.simCardSN || '',
      "lastSeen": device.lastSeen || '',
    }));

    // Извикване на функцията за експорт
    exportToExcel(exportData, 'filtered_devices.xlsx', 'Filtered Devices', {
      addFilters: true,
    });
  };

  if (loading) return <Typography>Loading devices...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Grid container spacing={2} style={{ height: '100vh' }}>
      <Grid item xs={12} style={{ height: '100%', overflow: 'auto' }}>

        <DeviceFilters
          filters={filters}
          onFilterChange={(updatedFilters) => {
            setFilters(updatedFilters); // Запазете текущите филтри
            handleFilterChange(updatedFilters); // Приложете филтрите
          }}
        />

        <TableContainer component={Paper}>
          <Typography variant="h4" gutterBottom>
            PMI Devices
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginBottom: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialog('create')}
            >
              Add Device
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportFilteredDevices}
            >
              Export to Excel
            </Button>
          </div>

          <Typography variant="h6" gutterBottom>
            Devices matching filters And: {filteredDevices.length}

          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Indicator</strong></TableCell> {/* Новата колонка */}
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>POS Name</strong></TableCell>
                <TableCell><strong>Trade Segment</strong></TableCell>
                <TableCell><strong>KA name</strong></TableCell>
                <TableCell><strong>POS Type</strong></TableCell>
                <TableCell><strong>Placement</strong></TableCell>
                <TableCell><strong>Manned</strong></TableCell>


                <TableCell><strong>City</strong></TableCell>
                <TableCell><strong>TA Actual</strong></TableCell>
                <TableCell><strong>TAL Actual</strong></TableCell>
                <TableCell><strong>Address</strong></TableCell>
                <TableCell><strong>Serial Number</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedDevices.map((device) => (
                <TableRow
                  key={device._id}
                  onClick={() => handleOpenDialog('edit', device)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    {device.serialNumber ? (
                      <CheckCircle style={{ color: 'green' }} />
                    ) : (
                      <Cancel style={{ color: 'red' }} />
                    )}
                  </TableCell>
                  <TableCell>{device.name}</TableCell>
                  <TableCell>{device.posName || 'N/A'}</TableCell>
                  <TableCell>{device.tradeSegment || 'N/A'}</TableCell>
                  <TableCell>{device.kaName || 'N/A'}</TableCell>
                  <TableCell>{device.posTypeNew || 'N/A'}</TableCell>
                  <TableCell>{device.placementNew || 'N/A'}</TableCell>
                  <TableCell>{device.manned || 'N/A'}</TableCell>


                  <TableCell>{device.city || 'N/A'}</TableCell>
                  <TableCell>{device.talActual || 'N/A'}</TableCell>
                  <TableCell>{device.taActual || 'N/A'}</TableCell>
                  <TableCell>{device.address || 'N/A'}</TableCell>
                  <TableCell>{device.serialNumber || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Пагинация */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <div>
            <Typography>Rows per page:</Typography>
            <select value={devicesPerPage} onChange={handleDevicesPerPageChange}>
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div>
          <Pagination
            count={Math.ceil(filteredDevices.length / devicesPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </Grid>

      <DeviceFormDialog
        open={openDialog}
        onClose={handleDialogClose}
        onSave={handleDeviceSave}
        device={dialogType === 'edit' ? selectedDevice : null}
        uniqueValues={uniqueValues}
        setUniqueValues={setUniqueValues} // Уверете се, че това е функция
        users={users}
        setUsers={setUsers} // Предаване на setUsers
      />
    </Grid>
  );
};

export default PmiDevicesTable;
