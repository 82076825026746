import React, { useState, useEffect } from 'react';
import { fetchPlaylists } from '../services/api'; // Импортирай API функцията
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PlaylistsList = () => {
    const [playlists, setPlaylists] = useState([]);

    useEffect(() => {
        const loadPlaylists = async () => {
            try {
                const data = await fetchPlaylists();
                setPlaylists(data);
            } catch (error) {
                console.error('Error fetching playlists:', error);
            }
        };

        loadPlaylists();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>Playlists</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {playlists.map((playlist) => (
                        <TableRow key={playlist._id}>
                            <TableCell>{playlist.name}</TableCell>
                            <TableCell>{playlist.description || 'No description'}</TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={`/edit-playlist/${playlist._id}`}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default PlaylistsList;