import React, { useState, useEffect, useRef } from 'react';
import { updateFile, listFiles, deleteFile, fetchCategories, fetchFileCampaigns } from '../services/api';
import CloseIcon from '@mui/icons-material/Close';
import Close from '@mui/icons-material/Close';
import './FileList.css'; // Файл със стилове
// deleteElementFromPlaylist as deleteFile
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Modal,
  Box,
  TextField
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const FileList = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [fileCampaigns, setFileCampaigns] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterOrientation, setFilterOrientation] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [addingNewFolder, setAddingNewFolder] = useState(false);
  const [filterFolder, setFilterFolder] = useState('');

  const rowRefs = useRef({}); // Референции за редовете

  useEffect(() => {
    const fetchFilesAndCategories = async () => {
      try {
        const { files } = await listFiles();
        const fetchedCategories = await fetchCategories();
        setFiles(files || []);
        setCategories(fetchedCategories || []);
      } catch (error) {
        console.error('Error fetching files or categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilesAndCategories();
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    // Сортиране на файловете
    const sortedFiles = [...files].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setFiles(sortedFiles);
  };

  const uniqueFileTypes = [...new Set(files.map((file) => file.fileType).filter(Boolean))];
  const uniqueOrientations = [...new Set(files.map((file) => file.orientation).filter(Boolean))];
  const uniqueFolders = Array.from(new Set(files.map((file) => file.folder).filter(Boolean)));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleFilterOrientationChange = (event) => {
    setFilterOrientation(event.target.value);
  };

  const handleFilterStartDateChange = (event) => {
    setFilterStartDate(event.target.value);
  };

  const handleFilterEndDateChange = (event) => {
    setFilterEndDate(event.target.value);
  };

  const filteredFiles = files.filter((file) => {
    const matchesSearch = Object.values(file).some((value) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(searchQuery);
      }
      return false;
    });

    const matchesType = filterType ? file.fileType === filterType : true;
    const matchesOrientation = filterOrientation ? file.orientation === filterOrientation : true;
    const matchesStartDate = filterStartDate ? new Date(file.validFrom) >= new Date(filterStartDate) : true;
    const matchesEndDate = filterEndDate ? new Date(file.validTo) <= new Date(filterEndDate) : true;
    const matchesFolder = filterFolder ? file.folder === filterFolder : true;

    return matchesSearch && matchesType && matchesOrientation && matchesStartDate && matchesEndDate && matchesFolder;
  });

  const handleUpdateFile = async (fileId, updatedData) => {
    try {
      const updatedFile = await updateFile(fileId, updatedData);

      // Локална актуализация на списъка с файлове
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === fileId ? { ...file, ...updatedData } : file
        )
      );

      // Обновяване на избрания файл, ако това е редактираният файл
      if (selectedFile && selectedFile._id === fileId) {
        setSelectedFile({ ...selectedFile, ...updatedData });
      }

      // Скрол до файла
      if (rowRefs.current[fileId]) {
        rowRefs.current[fileId].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      // alert('File updated successfully');
    } catch (error) {
      console.error('Error updating file:', error);
      alert('Failed to update file');
    }
  };


  const handleDeleteFile = async (fileId) => {
    try {
      await deleteFile(fileId);
      setFiles((prevFiles) => prevFiles.filter((file) => file._id !== fileId));
      if (selectedFile && selectedFile._id === fileId) {
        setSelectedFile(null);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('Failed to delete file.');
    }
  };

  const handleSelectFile = (file) => {
    setSelectedFile(file);

    // Скрол до реда на избрания файл
    if (rowRefs.current[file._id]) {
      rowRefs.current[file._id].scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      console.error(`No reference for file ID: ${file._id}`);
    }
  };

  const handleOrientationChange = (orientation) => {
    if (selectedFile) {
      const updatedData = { ...selectedFile, orientation };

      // Актуализация в базата данни
      handleUpdateFile(selectedFile._id, updatedData);

      // Локална актуализация за моментален рефреш на интерфейса
      setSelectedFile(updatedData);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === selectedFile._id ? updatedData : file
        )
      );
    }
  };


  const handlePlacementChange = (placement) => {
    if (selectedFile) {
      const updatedPlacements = selectedFile.placements || [];
      const updatedData = {
        ...selectedFile,
        placements: updatedPlacements.includes(placement)
          ? updatedPlacements.filter((p) => p !== placement)
          : [...updatedPlacements, placement],
      };
      handleUpdateFile(selectedFile._id, updatedData);
      setSelectedFile(updatedData);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === selectedFile._id ? updatedData : file
        )
      );
    }
  };

  const handleCategoryChange = async (selectedCategories) => {
    if (selectedFile) {
      const updatedFile = { ...selectedFile, categories: selectedCategories };

      // Обновяване в локалното състояние
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === selectedFile._id ? updatedFile : file
        )
      );

      // Обновяване в базата данни
      try {
        await handleUpdateFile(selectedFile._id, { categories: selectedCategories });
        setSelectedFile(updatedFile); // Обновяване на избрания файл
        console.log('Categories updated successfully');
      } catch (error) {
        console.error('Error updating categories:', error);
      }
    }
  };

  const handleFolderChange = (folder) => {
    if (selectedFile) {
      const updatedData = { ...selectedFile, folder };
      handleUpdateFile(selectedFile._id, updatedData);

      setSelectedFile(updatedData);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === selectedFile._id ? updatedData : file
        )
      );
    }
  };

  const handleParentFileChange = (parentFileId) => {
    if (selectedFile) {
      const updatedData = { ...selectedFile, parentFileId };
      handleUpdateFile(selectedFile._id, updatedData);

      setSelectedFile(updatedData);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file._id === selectedFile._id ? updatedData : file
        )
      );
    }
  };

  const handleThumbnailClick = (file) => {
    setCurrentFile(file);
    setOpen(true);
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const campaigns = await fetchFileCampaigns(selectedFile._id);
        console.log('Fetched campaigns:', campaigns); // Проверете дали данните са коректни
        setFileCampaigns(campaigns);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    if (selectedFile) {
      fetchCampaigns();
    }
  }, [selectedFile]);


  const handleClose = () => {
    setOpen(false);
    setCurrentFile(null);
  };

  return (
    <Grid container spacing={2} style={{ height: '100vh' }}>
      <Grid
        item
        xs={selectedFile ? 8 : 12}
        style={{ height: '100%', overflow: 'auto', borderRight: selectedFile ? '1px solid #ccc' : 'none' }}
      >
        <Typography variant="h5" gutterBottom>
          Files
        </Typography>
        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ flex: 1 }}
          />
          <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <InputLabel>Folder</InputLabel>
            <Select
              value={filterFolder}
              onChange={(e) => setFilterFolder(e.target.value)}
              label="Folder"
            >
              <MenuItem value="">All</MenuItem>
              {Array.from(new Set(files.map((file) => file.folder).filter(Boolean))).map((folder) => (
                <MenuItem key={folder} value={folder}>
                  {folder}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={filterType}
              onChange={handleFilterTypeChange}
              label="Type"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueFileTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <InputLabel>Orientation</InputLabel>
            <Select
              value={filterOrientation}
              onChange={handleFilterOrientationChange}
              label="Orientation"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueOrientations.map((orientation) => (
                <MenuItem key={orientation} value={orientation}>
                  {orientation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Valid From"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={filterStartDate}
            onChange={handleFilterStartDateChange}
          />
          <TextField
            label="Valid To"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={filterEndDate}
            onChange={handleFilterEndDateChange}
          />
        </div>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>

            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="sortable-header" onClick={() => handleSort('thumbnailPath')}>Thumbnail</TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('originalFileName')}>
                    Name {sortConfig.key === 'originalFileName' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('fileSize')}>
                    Size {sortConfig.key === 'fileSize' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('validFrom')}>
                    Valid From {sortConfig.key === 'validFrom' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('validTo')}>
                    Valid To {sortConfig.key === 'validTo' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('fileType')}>
                    Type {sortConfig.key === 'fileType' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell className="sortable-header" onClick={() => handleSort('orientation')}>
                    Orientation {sortConfig.key === 'orientation' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFiles.map((file) => {
                  const now = new Date();
                  const validFrom = new Date(file.validFrom);
                  const validTo = new Date(file.validTo);
                  let dotColor = '';

                  if (validTo < now) {
                    dotColor = 'red'; // Изтекла валидност
                  } else if (validFrom > now) {
                    dotColor = 'blue'; // Бъдеща валидност
                  } else if ((validTo - now) / (1000 * 60 * 60 * 24) <= 7) {
                    dotColor = 'orange'; // Изтичаща валидност
                  } else {
                    dotColor = 'green'; // Валидна
                  }

                  return (
                    <TableRow
                      key={file._id}
                      ref={(el) => (rowRefs.current[file._id] = el)}
                      className={selectedFile && selectedFile._id === file._id ? 'selected-row' : ''}
                    >
                      <TableCell>
                        <span
                          style={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: dotColor,
                            marginRight: '5px',
                          }}
                        ></span>
                        {file.thumbnailPath ? (
                          <img
                            src={`https://on-labs.eu/thumb/${file.thumbnailPath.split('/').pop()}`}
                            alt={file.originalFileName || 'Thumbnail'}
                            style={{ width: '80px', height: 'auto', objectFit: 'cover', cursor: 'pointer' }}
                            onClick={() => handleThumbnailClick(file)}
                          />
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>{file.originalFileName || file.fileName}</TableCell>
                      <TableCell>  {file.fileSize ? `${(file.fileSize / (1024 * 1024)).toFixed(2)} Mb` : 'N/A'}
                      </TableCell>
                      <TableCell>{file.validFrom || 'N/A'}</TableCell>
                      <TableCell>{file.validTo || 'N/A'}</TableCell>
                      <TableCell>{file.fileType || 'N/A'}</TableCell>
                      <TableCell>{file.orientation || 'N/A'}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleSelectFile(file)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteFile(file._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>


            </Table>
            {/* Модал за преглед */}
            <Modal open={open} onClose={handleClose}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  maxWidth: '600px',
                  maxHeight: '90vh',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 2,
                  overflow: 'auto',
                  borderRadius: '8px',

                }}
              >
                {/* Бутон за затваряне */}
                <IconButton
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 10,
                    color: 'grey.500',
                  }}
                >
                  <Close />
                </IconButton>

                {/* Видеоплейър */}
                {currentFile && currentFile.fileType.startsWith('video') ? (
                  <>
                    <video
                      //src={currentFile.localPath.replace('/var/www', 'https://on-labs.eu')}
                      src={
                        currentFile.localPath
                          ? currentFile.localPath.replace('/var/www', 'https://on-labs.eu')
                          : currentFile.url || ''
                      }
                      controls
                      autoPlay
                      style={{
                        width: '100%',
                        maxHeight: '50vh',
                        objectFit: 'contain',
                      }}
                    />

                    {/* Информация за видеото */}
                    <Box
                      sx={{
                        mt: 2,
                        p: 1,
                        bgcolor: 'grey.100',
                        borderRadius: '4px',
                      }}
                    >
                      <Typography variant="h6">Video Information</Typography>
                      <Typography variant="body1">
                        <strong>Name:</strong> {currentFile.originalFileName || currentFile.fileName}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Orientation:</strong> {currentFile.orientation || 'Unknown'}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Resolution:</strong>{' '}
                        {currentFile.ffmpegOrientation?.width && currentFile.ffmpegOrientation?.height
                          ? `${currentFile.ffmpegOrientation.width}x${currentFile.ffmpegOrientation.height}`
                          : 'Unknown'}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Type:</strong> {currentFile.fileType}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Placements:</strong>{' '}
                        {currentFile.placements?.length
                          ? currentFile.placements.join(', ')
                          : 'None'}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Categories:</strong>{' '}
                        {currentFile.categories?.length
                          ? currentFile.categories.map((categoryId) => {
                            const category = categories.find((cat) => cat._id === categoryId);
                            return category ? category.name : 'Unknown';
                          }).join(', ')
                          : 'None'}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <Typography variant="h6">Preview not available</Typography>
                )}
              </Box>
            </Modal>


          </TableContainer>
        )}
      </Grid>

      {selectedFile && (
        <Grid item xs={4} style={{ height: '100%', overflow: 'auto' }}>
          <Card key={selectedFile._id}>
            <CardHeader title="File Details"
              action={
                <IconButton onClick={() => setSelectedFile(null)}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>
              <div className='info-row'>
                <div className='info-label'>ID:</div>
                <div className='info-value'>{selectedFile._id}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>Original Name:</div>
                <div className='info-value'>{selectedFile.originalFileName}</div>
              </div>
              <div className='info-row'>
                <div className='info-label'>Orientation:</div>
                <div className='info-value'>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="orientation">Orientation</InputLabel>
                    <Select id="orientation"
                      label="Orientation"
                      value={selectedFile.orientation || 'Landscape'}
                      onChange={(e) => handleOrientationChange(e.target.value)}
                    >
                      <MenuItem value="Landscape">Landscape</MenuItem>
                      <MenuItem value="Portrait">Portrait</MenuItem>
                      <MenuItem value="Portrait-180">Portrait-180</MenuItem>
                      <MenuItem value="Portrait Left">Portrait Left</MenuItem>
                      <MenuItem value="Portrait Right">Portrait Right</MenuItem>
                      <MenuItem value="Landscape-180">Landscape-180</MenuItem>
                      <MenuItem value="Lanscape-768x704">Lanscape-768x704</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="info-row">
                <div className="info-label">Placement:</div>
                <div className="info-value">
                  {['Inside', 'Outside', 'Immersive zone', 'EducationA', 'EducationB', 'EducationC', 'EducationD', 'EducationE'].map((placement) => (
                    <label key={placement}>
                      <input
                        type="checkbox"
                        value={placement}
                        checked={selectedFile.placements?.includes(placement)}
                        onChange={() => handlePlacementChange(placement)}
                      />
                      {placement}
                    </label>
                  ))}
                </div>

              </div>
              {/* Категории */}
              <div className="info-row">
                <div className="info-label">Categories:</div>
                <div className="info-value">
                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                    <InputLabel id="categories-label">Categories</InputLabel>
                    <Select
                      labelId="categories-label"
                      multiple
                      value={selectedFile.categories || []}
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                          {selected.map((id) => {
                            const category = categories.find((cat) => cat._id === id);
                            return category ? (
                              <Chip
                                key={id}
                                label={category.name}
                                onDelete={() => handleCategoryChange(selectedFile.categories.filter((catId) => catId !== id))}
                                style={{ margin: '2px' }}
                              />
                            ) : null;
                          })}
                        </div>
                      )}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category._id} value={category._id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                </div>
              </div>
              <div className="info-row">
                <div className="info-label">Valid From:</div>
                <div className="info-value">
                  <input
                    type="date"
                    value={selectedFile.validFrom ? selectedFile.validFrom.split('T')[0] : ''}
                    onChange={(e) => {
                      const newValidFrom = `${e.target.value}T00:00:00.000Z`;
                      setSelectedFile({ ...selectedFile, validFrom: newValidFrom }); // Локална актуализация
                      handleUpdateFile(selectedFile._id, { validFrom: newValidFrom }); // Запис в базата
                    }}
                  />
                </div>
              </div>
              <div className="info-row">
                <div className="info-label">Valid To:</div>
                <div className="info-value">
                  <input
                    type="date"
                    value={selectedFile.validTo ? selectedFile.validTo.split('T')[0] : ''}
                    onChange={(e) => {
                      const newValidTo = `${e.target.value}T00:00:00.000Z`;
                      setSelectedFile({ ...selectedFile, validTo: newValidTo }); // Локална актуализация
                      handleUpdateFile(selectedFile._id, { validTo: newValidTo }); // Запис в базата
                    }}
                  />
                </div>
              </div>
              <div className='info-row'>
                <div className='info-label'>Folder:</div>
                <div className='info-value'>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="folder-select">Folder</InputLabel>
                    <Select
                      labelId="folder-select"
                      value={selectedFile.folder || ''}
                      onChange={(e) => {
                        if (e.target.value === 'add-new') {
                          setAddingNewFolder(true);
                        } else {
                          handleFolderChange(e.target.value);
                        }
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {uniqueFolders.map((folder) => (
                        <MenuItem key={folder} value={folder}>
                          {folder}
                        </MenuItem>
                      ))}
                      <MenuItem value="add-new">Add new folder</MenuItem>
                    </Select>
                  </FormControl>
                  {addingNewFolder && (
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Enter new folder name"
                      onBlur={(e) => {
                        const newFolder = e.target.value.trim();
                        if (newFolder) {
                          handleFolderChange(newFolder);
                          setAddingNewFolder(false);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const newFolder = e.target.value.trim();
                          if (newFolder) {
                            handleFolderChange(newFolder);
                            setAddingNewFolder(false);
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <div className='info-row'>
                <div className='info-label'>Parent File:</div>
                <div className='info-value'>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="parent-file">Parent File</InputLabel>
                    <Select
                      labelId="parent-file"
                      value={selectedFile.parentFileId || ''}
                      onChange={(e) => handleParentFileChange(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {files
                        .filter((file) => file._id !== selectedFile._id)
                        .map((file) => (
                          <MenuItem key={file._id} value={file._id}>
                            {file.originalFileName || file.fileName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {fileCampaigns.length > 0 ? (
                <div className="info-row">
                  <div className="info-label">Campaigns:</div>
                  <div className="info-value">
                    <ul>
                      {fileCampaigns.map((campaign) => (
                        <li key={campaign._id}>{campaign.name}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="info-row">
                  <div className="info-label">Campaigns:</div>
                  <div className="info-value">Not part of any campaigns</div>
                </div>
              )}

            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default FileList;