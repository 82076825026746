import React, { useState } from 'react';
import axios from 'axios';

const AddPlaylist = ({ onSuccess }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [source, setSource] = useState('local'); // Източник по подразбиране
    const [playlistId, setPlaylistId] = useState(''); // playlistId за външни плейлисти

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            name,
            description,
            source,
            playlistId: source === 'external' ? playlistId : null, // Добавя playlistId само ако source е external
        };

        try {
            const response = await axios.post('https://on-labs.eu/api/vxtPlaylists/createPl', payload);
            alert('Playlist created successfully!');
            setName('');
            setDescription('');
            setSource('local');
            setPlaylistId('');
            if (onSuccess) onSuccess(response.data.playlist);
        } catch (error) {
            console.error('Error creating playlist:', error);
            alert('Failed to create playlist');
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <label>
                Playlist Name:
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </label>
            <label>
                Description:
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </label>
            <label>
                Source:
                <select value={source} onChange={(e) => setSource(e.target.value)}>
                    <option value="local">Local</option>
                    <option value="external">External</option>
                </select>
            </label>
            {source === 'external' && (
                <label>
                    Playlist ID:
                    <input
                        type="text"
                        value={playlistId}
                        onChange={(e) => setPlaylistId(e.target.value)}
                        placeholder="Enter external playlist ID"
                        required
                    />
                </label>
            )}
            <button type="submit" style={{ marginTop: '1rem' }}>Create Playlist</button>
        </form>
    );
};

export default AddPlaylist;