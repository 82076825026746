import React, { useState, useEffect } from 'react';
import DeviceFilters from '../components/pmiDeviceFilters';
//import SimulationResultsTable from './SimulationResultsTable';
import FilteredCampaigns from '../components/FilteredCampaigns';
import { fetchSimulationResults } from '../services/api';

const SimulationResultsPage = () => {
  const [results, setResults] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [devicesPerPage, setDevicesPerPage] = useState(100);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const response = await fetchSimulationResults({
          page: currentPage,
          limit: 100, // Уверете се, че devicesPerPage е дефинирана преди това
          filters,
        });
  
        const { devices, campaigns, totalPages } = response;
  
        setResults(devices);
        setCampaigns(campaigns);
        setTotalPages(totalPages);
      } catch (error) {
        console.error('Error fetching simulation results:', error);
      }
    };

    fetchResults();
  }, [currentPage, devicesPerPage, filters]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1); // Рестартиране на страницата при промяна на филтрите
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="simulation-results-page">
      <h1>Simulation Results</h1>

      <div className="filters-section">
        <DeviceFilters onFilterChange={handleFilterChange} />
      </div>
{/*
      <div className="results-section">
        <SimulationResultsTable
          results={results}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <div className="campaigns-section">
        <FilteredCampaigns campaigns={campaigns} />
      </div>
      */}
    </div>
  );
};

export default SimulationResultsPage;
