import React, { useState, useEffect } from 'react';
import { fetchSimulationResults } from '../services/api';
import DeviceFilters from '../components/pmiDeviceFilters';
import './SimulationResultsTable.css';

const CampaignList = ({ campaigns }) => (
    <div className="campaign-list">
      <h3>Matched Campaigns</h3>
      <ul>
        {campaigns.map((campaign, index) => (
          <li key={index}>
            <strong>{campaign.campaignName}</strong>
            <p>Trade Segment: {campaign.tradeSegment.join(', ') || 'N/A'}</p>
            <p>KA Name: {campaign.kaName.join(', ') || 'N/A'}</p>
            <p>POS Type: {campaign.posTypeNew.join(', ') || 'N/A'}</p>
            <p>Placement New: {campaign.placementNew.join(', ') || 'N/A'}</p>
            <p>Manned: {campaign.manned.join(', ') || 'N/A'}</p>
          </li>
        ))}
      </ul>
    </div>
  );

const SimulationResultsTable = () => {
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(100);
    const [pagination, setPagination] = useState({ totalDevices: 0, totalPages: 0 });
    const [mannedDistribution, setMannedDistribution] = useState({}); // Държи разпределението по manned
    const [filters, setFilters] = useState({});
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const loadResults = async () => {
            try {
                const data = await fetchSimulationResults(page, limit, filters);
                console.log('Raw Device Data:', data.results); // Проверка на данните от API

                setResults(data.results);
                setPagination({
                    totalDevices: data.totalDevices,
                    totalPages: data.totalPages,
                });

                // Изчисляване на разпределението по manned
                const distribution = data.results.reduce((acc, result) => {
                    const mannedValue = result.criteria?.manned;
                    if (mannedValue) {
                        acc[mannedValue] = (acc[mannedValue] || 0) + 1;
                    } else {
                        acc["Missing"] = (acc["Missing"] || 0) + 1;
                    }
                    return acc;
                }, {});
                setMannedDistribution(distribution);
            } catch (error) {
                console.error('Error loading simulation results:', error);
            }
        };
        loadResults();
    }, [page, limit, filters]);

    const handleFilterChange = (updatedFilters) => {
        setFilters(updatedFilters);
        setPage(1); // Рестартиране на страницата при промяна на филтъра
    };
    const [devicesPerPage, setDevicesPerPage] = useState(100);

    const handleDevicesPerPageChange = (e) => {
      setDevicesPerPage(Number(e.target.value)); // Обновява стойността
    };
    return (
        <div className="simulation-table-container">
            <h1>Simulation Results</h1>
            <DeviceFilters onFilterChange={handleFilterChange} />
  {/*}          <CampaignList campaigns={campaigns} /> */}
            <p>Total Devices: {pagination.totalDevices}</p>
            <p>Page {page} of {pagination.totalPages}</p>

            {/* Показване на разпределението по manned */}
            <div className="manned-distribution">
                <h2>Manned Distribution</h2>
                <ul>
                    {Object.entries(mannedDistribution).map(([key, count]) => (
                        <li key={key}>
                            <strong>{key === "Missing" ? "No Manned Field" : key}:</strong> {count}
                        </li>
                    ))}
                </ul>
            </div>

            <table border="1" width="100%" className="simulation-table">
                <thead>
                    <tr>
                        <th style={{ width: '10%' }}>Device</th>
                        <th style={{ width: '20%' }}>Device Criteria</th>
                        <th style={{ width: '70%' }}>Campaigns</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <tr key={index}>
                            <td className="device-column">{result.device}</td>
                            <td>
                                <div className="criteria-container">
                                    <p><strong>Trade Segment:</strong> {result.criteria.tradeSegment || 'N/A'}</p>
                                    <p><strong>KA Name:</strong> {result.criteria.kaName || 'N/A'}</p>
                                    <p><strong>POS Type:</strong> {result.criteria.postType || 'N/A'}</p>
                                    <p><strong>Placement New:</strong> {result.criteria.placementNew || 'N/A'}</p>
                                    <p><strong>Manned:</strong> {result.criteria.manned === undefined ? 'Missing' : result.criteria.manned}</p>

                                </div>
                            </td>
                            <td>
                                <table border="1" width="100%" className="campaigns-table">
                                    <thead>
                                        <tr>
                                            <th>Campaign Name</th>
                                            <th>Trade Segment</th>
                                            <th>KA Name</th>
                                            <th>POS Type</th>
                                            <th>Placement New</th>
                                            <th>Manned</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {result.filteredCampaigns.map((campaign, idx) => (
                                            <tr key={idx}>
                                                <td>{campaign.campaignName}</td>
                                                <td>{campaign.tradeSegment?.join(', ') || 'N/A'}</td>
                                                <td>{campaign.kaName?.join(', ') || 'N/A'}</td>
                                                <td>{campaign.posTypeNew?.join(', ') || 'N/A'}</td>
                                                <td>{campaign.placementNew?.join(', ') || 'N/A'}</td>
                                                <td>{campaign.manned?.join(', ') || 'N/A'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-controls">
                <button disabled={page === 1} onClick={() => setPage(page - 1)}>Previous</button>
                <button disabled={page === pagination.totalPages} onClick={() => setPage(page + 1)}>Next</button>
            </div>
            <label>Devices Per Page:</label>
    <select value={devicesPerPage} onChange={handleDevicesPerPageChange}>
      <option value={10}>10</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
      <option value={200}>200</option>
    </select>
        </div>
    );
};

export default SimulationResultsTable;
