import axios from 'axios';

const API_BASE_URL = 'https://on-labs.eu/api/media'; // Или пълния път към вашия бекенд

export async function generateUploadUrl(fileName, fileType) {
    const response = await axios.get(`${API_BASE_URL}/generate-upload-url`, {
        params: { fileName, fileType },
    });
    console.log('generateUploadUrl response:', response.data);
    let { url, fileName: returnedFileName } = response.data;
    url = url.replace('https://on-labs-space.fra1.digitaloceanspaces.com', 'https://on-labs-space.fra1.cdn.digitaloceanspaces.com');
    return { url, fileName: returnedFileName };
    //return response.data; // { url, fileName }
}

export async function listFiles() {
    const response = await axios.get('/api/media/list-files');
    return response.data.files;
}

export async function deleteFile(fileName) {
    await axios.delete(`${API_BASE_URL}/delete/${fileName}`);
}

export async function downloadFile(fileName) {
    const response = await axios.get(`${API_BASE_URL}/download/${fileName}`, {
        responseType: 'blob', // Връща бинарни данни
    });
    return response.data;
}