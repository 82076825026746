import React, { useEffect, useState } from 'react';
import '../components/DevicesList.css';
import { getDevicePlaylist } from '../services/api';
import axios from 'axios';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPreviewPlaylist from '../components/VideoPreviewPlaylist';
import DeviceFilters from '../components/DeviceFilters';


function Devices() {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [filters, setFilters] = useState({
    deviceName: '',
    isRegistered: '',
    tradeSegment: [],
    kaName: [],
    posType: [],
    placementNew: [],
    manned: [],
    tire: [],
    screenType: [],
    localDeviceFilters: {
      name: '',
      sn: '',
      registered: '',
      activeMac: '',
  },
  });



  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('/api/devices');
        setDevices(response.data.devices || []);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (devices.length > 0) {
      setFilteredDevices(devices);
    }
  }, [devices]); // Следи промените в `devices`

  const handleFilterChange = (filters) => {
    const { localDeviceFilters, ...locationNewFilters } = filters;

    const filtered = devices.filter((device) => {
      const matchesName =
        !filters.deviceName || device.name?.toLowerCase().includes(filters.deviceName.toLowerCase());
      const matchesRegistration =
        filters.isRegistered === '' ||
        (filters.isRegistered === 'true' && device.serialNumber) ||
        (filters.isRegistered === 'false' && !device.serialNumber);
      const matchesTradeSegment =
        filters.tradeSegment.length === 0 || filters.tradeSegment.includes(device.tradeSegment);
      const matchesKaName =
        filters.kaName.length === 0 || filters.kaName.includes(device.kaName);
      const matchesPosType =
        filters.posType.length === 0 || filters.posType.includes(device.posTypeNew);
      const matchesplacementNew =
        filters.placementNew.length === 0 || filters.placementNew.includes(device.placementNew);
      const matchesmanned =
        filters.manned.length === 0 || filters.manned.includes(device.manned);
      const matchestire =
        filters.tire.length === 0 || filters.tire.includes(device.tire);
      const matchesscreenType =
        filters.screenType.length === 0 || filters.screenType.includes(device.screenType);
      
      // Филтриране по locationNew
      const matchesLocationName =
      !locationNewFilters.deviceName ||
      device.locationNew?.name?.toLowerCase().includes(locationNewFilters.deviceName.toLowerCase());

        // Филтриране по localDevice
      const matchesSerialNumber =
        !localDeviceFilters.sn ||
        device.sn?.toLowerCase().includes(localDeviceFilters.sn.toLowerCase());
      const matchesRegistered =
        localDeviceFilters.registered === '' ||
        (localDeviceFilters.registered === 'true' && device.registered) ||
        (localDeviceFilters.registered === 'false' && !device.registered);
      return matchesLocationName && matchesSerialNumber && matchesRegistered && matchesName && matchesRegistration && matchesTradeSegment && matchesKaName && matchesPosType && matchesplacementNew && matchesmanned && matchestire && matchesscreenType;
    });
    setFilteredDevices(filtered);
  };

  const handleDeviceClick = async (device) => {
    setSelectedDevice(device);
    try {
      const playlistData = await getDevicePlaylist(device.sn);
      setPlaylist(playlistData.playlist || []);
    } catch (error) {
      console.error('Error fetching playlist:', error);
      setPlaylist([]);
    }
  };

  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{typeof item === 'object' ? JSON.stringify(item, null, 2) : item}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    } else {
      return value || 'N/A';
    }
  };

  // if (loading) return <Typography>Loading devices...</Typography>;
  // if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Grid container spacing={3}>
      {/* Device list table */}
      <Grid item xs={12} sm={selectedDevice ? 6 : 12} md={selectedDevice ? 8 : 12}>
        <div className="device-list-container">
          <Card style={{ padding: '20px' }}>

            <DeviceFilters
              filters={filters}
              onFilterChange={(updatedFilters) => {
                setFilters(updatedFilters);
                handleFilterChange(updatedFilters); // Приложете новите филтри
              }}
            />

            <Typography variant="h6">Devices</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>POS Name</strong></TableCell>
                  <TableCell><strong>City</strong></TableCell>
                  <TableCell><strong>Serial Number</strong></TableCell>
                  <TableCell><strong>Last Updated</strong></TableCell>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>Location Name</strong></TableCell>
                  <TableCell><strong>Orientation</strong></TableCell>
                  <TableCell><strong>Placement</strong></TableCell>
                  <TableCell><strong>Manned</strong></TableCell>
                  <TableCell><strong>POS Type</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDevices.map((device) => (
                  <TableRow
                    key={device._id}
                    onClick={() => handleDeviceClick(device)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedDevice?._id === device._id ? '#e0f7fa' : 'inherit',
                    }}
                  >
                    <TableCell>{device.location?.posName || 'N/A'}</TableCell>
                    <TableCell>{device.location?.city || 'N/A'}</TableCell>
                    <TableCell>{device.sn}</TableCell>
                    <TableCell>
                      {new Date(device.updatedAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })}
                    </TableCell>
                    <TableCell>{device.registered ? 'Registered' : 'Not Registered'}</TableCell>
                    <TableCell>{device.location?.name || 'No Location'}</TableCell>
                    <TableCell>{device.locationNew?.orientation || 'No Location'}</TableCell>
                    <TableCell>{device.locationNew?.placementNew || 'No Placement'}</TableCell>
                    <TableCell>{device.locationNew?.manned || 'Unmanned'}</TableCell>
                    <TableCell>{device.locationNew?.posTypeNew || 'No PosType'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </Grid>

      {/* Device details and playlist */}
      <Grid item xs={12} sm={6} md={4}>
        <div className="device-details-container">
          {selectedDevice && (
            <Card style={{ padding: '20px' }}>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                {selectedDevice.location?.posName || 'No Location Name'}
              </Typography>
              <Typography variant="h6">Device Details</Typography>
              <Button
                style={{
                  position: 'fixed',
                  top: '90px',
                  right: '20px',
                  zIndex: 1000,
                }}
                variant="outlined"
                color="secondary"
                onClick={() => setSelectedDevice(null)}
              >
                Close
              </Button>
              {Object.entries(selectedDevice).map(([key, value]) => (
                <Accordion key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{renderValue(value)}</AccordionDetails>
                </Accordion>
              ))}

              <Typography variant="h6" style={{ marginTop: '20px' }}>Playlist</Typography>
              {playlist.length > 0 ? (
                <div>
                  <Table>
                    <TableBody>
                      {playlist.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <a href={item.localPath} target="_blank" rel="noopener noreferrer">
                              {item.filename}
                            </a>
                          </TableCell>
                          <TableCell>{item.campaign}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <VideoPreviewPlaylist key={selectedDevice?.sn} playlist={playlist} />
                </div>
              ) : (
                <Typography>No Playlist Available</Typography>
              )}
            </Card>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default Devices;
