// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-row {
    background-color: #f0f8ff;
  }
  
  .sortable-header {
    font-weight: bold; /* Удебелен текст */
    cursor: pointer;   /* Промяна на курсора */
    transition: color 0.2s ease; /* Анимация за плавен ефект */
}

.sortable-header:hover {
    color: #007bff; /* Промяна на цвета при насочване */
}
`, "",{"version":3,"sources":["webpack://./src/components/FileList.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB,EAAE,mBAAmB;IACtC,eAAe,IAAI,uBAAuB;IAC1C,2BAA2B,EAAE,6BAA6B;AAC9D;;AAEA;IACI,cAAc,EAAE,mCAAmC;AACvD","sourcesContent":[".selected-row {\n    background-color: #f0f8ff;\n  }\n  \n  .sortable-header {\n    font-weight: bold; /* Удебелен текст */\n    cursor: pointer;   /* Промяна на курсора */\n    transition: color 0.2s ease; /* Анимация за плавен ефект */\n}\n\n.sortable-header:hover {\n    color: #007bff; /* Промяна на цвета при насочване */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
