import React, { useState, useEffect } from 'react';
import CampaignEditor from '../components/CampaignEditor';
import { listCampaigns, createCampaign, updateCampaign, getDeviceCountByCampaign } from '../services/api'; // Импорт на правилните методи
import { getPublicPath } from '../utils/pathUtils';
import axios from 'axios';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Card
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';


const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [deviceCounts, setDeviceCounts] = useState({});
  const [dynamicSummaries, setDynamicSummaries] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [filters, setFilters] = useState({
  //  channel: '',
    posTypeNew: '',
    //typeIR: '',
    //kaName: '',
  });
  

  useEffect(() => {
    fetchCampaigns();
  }, []);


  const fetchCampaigns = async () => {
    try {
      const data = await listCampaigns();
      setCampaigns(data);

      // Извличане на броя устройства за всяка кампания
      const counts = {};
      const summaries = {};
      for (const campaign of data) {
        // console.log('campaign:', campaign.keyVisual); // Проверка за кампания
        try {
          counts[campaign._id] = await getDeviceCountByCampaign(campaign._id);
          summaries[campaign._id] = formatDynamicSummary(campaign);
        } catch {
          counts[campaign._id] = 0; // Ако има грешка, по подразбиране е 0
          summaries[campaign._id] = 'N/A';
        }
      }
      setDeviceCounts(counts);
      setDynamicSummaries(summaries);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };
  const fetchFilteredCampaigns = async () => {
    try {
      const { data } = await axios.get('/api/campaigns/filtered', {
        params: filters,
      });
      setCampaigns(data);
    } catch (error) {
      console.error('Error fetching filtered campaigns:', error);
    }
  };
  
  const formatDynamicSummary = (campaign) => {
    const fields = [
      'tradeSegment',
      'placementNew',
      'posTypeNew',
      'kaName',
      'manned',
    ];
    return fields
      .map((field) => {
        const value = campaign[field];
        return value && value.length > 0 ? `${field}: ${value.join(', ')}` : null;
      })
      .filter(Boolean)
      .join(' | ');
  };

  
  const handleSave = async (campaign) => {
    try {
      if (campaign._id) {
        await updateCampaign(campaign._id, campaign); // Използваме правилния метод за обновяване
      } else {
        await createCampaign(campaign); // Използваме правилния метод за създаване
      }
      fetchCampaigns(); // Обновяваме списъка след запис
      setEditorOpen(false); // Затваряме редактора
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };

  const handleEdit = (campaign) => {
    setSelectedCampaign(campaign);
    setEditorOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/campaigns/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    }
  };

  const handleAddNew = () => {
    setSelectedCampaign(null);
    setEditorOpen(true);
  };

  return (
    
    <div>
      <Typography variant="h4" gutterBottom>Campaigns</Typography>
      <Button variant="contained" color="primary" onClick={handleAddNew}>Add New Campaign</Button>


      <Table>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign._id}>

              <TableCell>
                {campaign.keyVisual ? (
                  campaign.keyVisual.fileType.startsWith('video') ? (
                    <video
                      src={getPublicPath(campaign.keyVisual.localPath || campaign.keyVisual.url || '')}
                      controls
                      style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
                      onClick={() => window.open(getPublicPath(campaign.keyVisual.localPath || campaign.keyVisual.url || ''), '_blank')}
                    />
                  ) : (
                    <img
                      src={getPublicPath(campaign.keyVisual.localPath)}
                      alt="Key Visual"
                      style={{ width: '150px', height: 'auto', cursor: 'pointer' }}
                      onClick={() => window.open(getPublicPath(campaign.keyVisual.localPath), '_blank')}
                    />
                  )
                ) : (
                  'No Key Visual'
                )}
              </TableCell>

              <TableCell>{campaign.name}</TableCell>
              <TableCell>
                {campaign.startDate && campaign.endDate
                  ? `${new Date(campaign.startDate).toLocaleDateString()} - ${new Date(campaign.endDate).toLocaleDateString()}`
                  : 'No validity'}
              </TableCell>
              <TableCell>Devices: {deviceCounts[campaign._id] || 'Loading...'}{' '}</TableCell>
              <TableCell>
                <Tooltip title={dynamicSummaries[campaign._id] || 'No dynamic fields'}>
                  <span>{dynamicSummaries[campaign._id] || 'No dynamic fields'}</span>
                </Tooltip>
              </TableCell>


              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEdit(campaign)}>Edit</Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(campaign._id)}
                  style={{ marginLeft: '10px' }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CampaignEditor
        open={isEditorOpen}
        onClose={() => setEditorOpen(false)}
        initialData={selectedCampaign}
        onSave={handleSave}
      />
    </div>
  );
};

export default Campaigns;