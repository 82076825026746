import React, { useState, useEffect, forwardRef, useImperativeHandle,useCallback } from 'react';
import { List, ListItem, Checkbox, Typography } from '@mui/material';
import { listFiles } from '../services/api'; // Импорт на API метода

//const CampaignFileList = ({ selectedFiles, onSelectFile, onFilesUpdated }) => {
  const CampaignFileList = forwardRef(({ selectedFiles, onSelectFile, onFilesUpdated }, ref) => {
  const [files, setFiles] = useState([]); // Локален стейт за всички налични файлове

  const refreshFiles = useCallback (async () => {
    try {
      const data = await listFiles(); // Извличане на файловете от API
      //console.log('Refreshing files:', data);
    const files = Array.isArray(data) ? data : data.files; // Уверете се, че форматът е правилен
      setFiles(Array.isArray(files) ? files : []);
      if (onFilesUpdated) {
        onFilesUpdated(files); // Уведомяване за обновения списък
      }
    } catch (error) {
      console.error('Error refreshing files:', error);
      setFiles([]); // При грешка задаваме празен списък
    }
  },[onFilesUpdated]);

  useImperativeHandle(ref, () => ({
    refreshFiles, // Експортираме метода за родителския компонент
  }));

  useEffect(() => {
    refreshFiles(); // Обновяване при първоначално зареждане
  }, [refreshFiles]);

  /*
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const data = await listFiles(); // Взима файловете от API
        const files = data.files
        setFiles(Array.isArray(files) ? files : []); // Проверка дали data е масив
      } catch (error) {
        console.error('Error fetching files:', error);
        setFiles([]); // В случай на грешка, задаваме празен масив
      }
    };

    fetchFiles();
  }, []);
*/
const handleToggle = (file) => {
  console.log('Before toggle selectedFiles:', selectedFiles);
  
  // Проверка дали selectedFiles е дефиниран и е масив
  const currentSelectedFiles = Array.isArray(selectedFiles) ? selectedFiles : [];
  
  const isSelected = currentSelectedFiles.some((f) => f._id === file._id);
  
  if (isSelected) {
    const updatedFiles = currentSelectedFiles.filter((f) => f._id !== file._id);
    console.log('File removed:', file);
    console.log('Updated selectedFiles:', updatedFiles);
    onSelectFile(updatedFiles);
  } else {
    const updatedFiles = [...currentSelectedFiles, file];
    console.log('File added:', file);
    console.log('Updated selectedFiles:', updatedFiles);
    onSelectFile(updatedFiles);
  }
};
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Select Files
      </Typography>
      <List>
        {files.map((file) => (
         <ListItem key={file._id} button onClick={() => handleToggle(file)}>
            <Checkbox
              checked={selectedFiles.some((f) => f._id === file._id)}
              tabIndex={-1}
              disableRipple
            />
            <Typography>{file.originalFileName || file.fileName}</Typography>
          </ListItem>
        ))}
        {files.length === 0 && <Typography>No files available.</Typography>} {/* Показва съобщение при празен списък */}
      </List>
    </div>
  );
});

export default CampaignFileList;