import React, { useState, useEffect } from 'react';
import { addContact } from '../services/api';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

const DeviceFormDialog = ({ open, onClose, onSave, device, uniqueValues, users, setUsers, setUniqueValues }) => {
    const [formValues, setFormValues] = useState({});
    const [searchTerms, setSearchTerms] = useState({});


    useEffect(() => {
        if (device) {
            setFormValues(device);
        } else {
            setFormValues({});
        }
        console.log('Loaded device:', device);

    }, [device]);
    
    useEffect(() => {
        console.log('Current formValues:', formValues);
    }, [formValues]);

    const handleAddContact = async (name) => {
        try {
            // Генериране на фиктивен имейл за контакта
            const generatedEmail = `${name.toLowerCase().replace(/\s+/g, '.')}@generated.com`;

            // Създаване на нов контакт
            const response = await addContact({ nameLocal: name, email: generatedEmail });
            const newContact = response.contact;

            // Добавяне на новия контакт в списъка с потребители
            setUsers((prev) => [...prev, newContact]);

            alert(`Contact "${name}" added successfully.`);
        } catch (err) {
            console.error('Error adding contact:', err);
            alert('Failed to add contact.');
        }
    };

    const handleChange = (field, value) => {
        console.log(`Changing ${field} to`, value); // Лог за дебъг
        setFormValues((prev) => ({ ...prev, [field]: value }));
        if (field === 'taActual' && !users.some((user) => user.nameLocal === value)) {
            handleAddContact(value); // Създаване на контакт, ако не съществува
        }
    };

    const handleSearchChange = (field, value) => {
        setSearchTerms((prev) => ({ ...prev, [field]: value }));
    };

    const filteredOptions = (field, options) => {
        const currentValue = formValues[field];
        const searchTerm = searchTerms[field] || '';
        const allOptions = currentValue && !options.includes(currentValue) ? [currentValue, ...options] : options;

        if (!searchTerm) return options;
        return allOptions.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const handleSave = () => {
        const defaultValues = {
            outputResolution: '1080p',
            connectivity: ['USB Dongle'],
            splitter: 'No',
            seasonality: 'All Year',
            tvModel: 'Unknown Model',
            playerModel: 'RBPI4',
            //isActive: 1,
        };

        const finalValues = { ...defaultValues, ...formValues };
        console.log('Final values:', finalValues);
        onSave(finalValues);
    };
    const handleAddCustomOption = (field, value) => {
        if (value && !uniqueValues[field]?.includes(value)) {
            setFormValues((prev) => ({ ...prev, [field]: value }));
            if (typeof setUniqueValues === 'function') {
                setUniqueValues((prev) => ({
                    ...prev,
                    [field]: [...(prev[field] || []), value],
                }));
            } else {
                console.warn('setUniqueValues is not defined or is not a function');
            }
        }
    };
    const handleIsActiveChange = (event) => {
        const value = parseInt(event.target.value); // Преобразуване в числова стойност
        console.log('Selected value for isActive:', value); // Лог за дебъг
        handleChange('isActive', value);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{device ? 'Edit Device' : 'Add New Device'}</DialogTitle>
            <DialogContent>
                {Object.entries({
                    name: 'Reev Code *',
                    posName: 'POS Name',
                    city: 'City',
                    address: 'Address',
                    talActual: 'TA Actual',
                    taActual: 'TAL Actual',
                    region: 'Region',
                    tradeSegment: 'Trade Segment',
                    posTypeNew: 'POS Type New',
                    kaName: 'KA Name',
                    placementNew: 'Placement New',
                    manned: 'Manned',
                    screenType: 'Screen Type',
                    tire: 'Tire',
                    simCardSN: 'SIM Card SN',
                    diagonal: 'Diagonal',
                    displayType: 'Display Type',
                    displayModel: 'Display Model',
                    orientation: 'Orientation',
                    macAddress: 'MAC Address',
                    serialNumber: 'Serial Number',
                    outputResolution: 'Output Resolution',
                    connectivity: 'Connectivity',
                    splitter: 'Splitter (Yes/No)',
                    seasonality: 'Seasonality',
                    playerModel: 'Player Model',
                    // isActive: 'Is Active',
                }).map(([key, label]) => (
                    ['posTypeNew', 'city', 'region', 'tradeSegment', 'orientation', 'screenType', 'kaName', 'manned', 'talActual', 'taActual', 'placementNew'].includes(key) ? (
                        <FormControl key={key} fullWidth style={{ marginBottom: '10px' }}>
                            <InputLabel>{label}</InputLabel>
                            <Select
                                value={formValues[key] || ''}
                                onChange={(e) => {
                                    handleChange(key, e.target.value);
                                    handleAddCustomOption(key, e.target.value);
                                }}
                                fullWidth
                                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                                renderValue={(selected) => (selected ? selected : 'Select an option')}
                            >
                                <MenuItem style={{ pointerEvents: 'none', opacity: 1 }}>
                                    <TextField
                                        placeholder="Type to add..."
                                        fullWidth
                                        value={searchTerms[key] || ''}
                                        onChange={(e) => handleSearchChange(key, e.target.value)}
                                        onMouseDown={(e) => e.stopPropagation()}
                                    />
                                </MenuItem>

                                {key === 'taActual'
                                    ? filteredOptions(
                                        key,
                                        formValues[key] && !users.map((user) => user.nameLocal).includes(formValues[key])
                                            ? [formValues[key], ...users.map((user) => user.nameLocal)]
                                            : users.map((user) => user.nameLocal)
                                    ).map((nameLocal) => (
                                        <MenuItem key={nameLocal} value={nameLocal}>
                                            {nameLocal}
                                        </MenuItem>
                                    ))
                                    : filteredOptions(
                                        key,
                                        formValues[key] && !uniqueValues[key]?.includes(formValues[key])
                                            ? [formValues[key], ...(uniqueValues[key] || [])]
                                            : uniqueValues[key] || []
                                    ).map((option) => (
                                        <MenuItem key={`option-${option}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>

                    ) : (
                        <TextField
                            key={key}
                            label={label}
                            value={formValues[key] || ''}
                            onChange={(e) => handleChange(key, e.target.value)}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                    )
                ))}
                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                    <InputLabel>Is Active*</InputLabel>
                    <Select
                        value={formValues.isActive !== undefined ? formValues.isActive : 1} // По подразбиране само ако isActive е undefined

                        onChange={handleIsActiveChange} // Извикване на помощната функция
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceFormDialog;
