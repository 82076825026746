export const getPublicPath = (path) => {
 //console.log('Received path:', path);

  if (!path) {
    console.warn('Path is undefined or null');
    return ''; // Връщане на празен низ или стойност по подразбиране
  }
    if (path.startsWith('/var/www/thumb')) {
      return path.replace('/var/www/thumb', 'https://on-labs.eu/thumb');
    }
    if (path.startsWith('/var/www/videos')) {
      return path.replace('/var/www/videos', 'https://on-labs.eu/videos');
    }
    return path;
  };
  