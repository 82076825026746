import React, { useEffect, useState } from 'react';
import { getActiveDevices } from '../services/api';
import { Bar, Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
} from 'chart.js';
import { Card, Grid, Typography } from '@mui/material';
import DevicesTable from '../components/DevicesTable';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function Dashboard() {
  const [devices, setDevices] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [groupedData, setGroupedData] = useState({});
  const [registeredTrue, setRegisteredTrue] = useState(0); // New state for registered count
  const [onlineDevices, setOnlineDevices] = useState(0); // New state for online devices

  useEffect(() => {
    async function fetchDevices() {
      try {
        const data = await getActiveDevices();
        const devices = data.devices || [];
        setDevices(devices);

        const cityCounts = devices.reduce((acc, device) => {
          const city = device.location?.city || 'No City';
          acc[city] = (acc[city] || 0) + 1;
          return acc;
        }, {});

        // Process data for charts
        const totalDevices = devices.length;
        const registeredTrue = devices.filter((device) => device.location).length;
        const registeredCount = devices.filter((device) => device.location).length;
        setRegisteredTrue(registeredCount); // Update state 
        const registeredFalse = totalDevices - registeredTrue;
        const registeredDevices = devices.filter((device) => device.location);
        // Calculate online devices
        const now = new Date();
        const onlineThreshold = 24 * 60 * 60 * 1000; // 5 minutes in milliseconds
        const onlineCount = devices.filter((device) => {
          const lastSeen = new Date(device.lastSeen);
          return now - lastSeen <= onlineThreshold;
        }).length;
        setOnlineDevices(onlineCount);

        // Process data for regions
        const regionCounts = devices.reduce((acc, device) => {
          const region = device.location?.region || 'No Region';
          acc[region] = (acc[region] || 0) + 1;
          return acc;
      }, {});


        const createdAtCounts = devices.reduce((acc, device) => {
          const date = new Date(device.createdAt).toISOString().split('T')[0];
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        // Group registered devices by TAL
        const talCounts = registeredDevices.reduce((acc, device) => {
          const tal = device.location.tal || 'No TAL';
          acc[tal] = (acc[tal] || 0) + 1;
          return acc;
      }, {});
      
        const registeredByDate = devices.reduce(
          (acc, device) => {
            const date = new Date(device.createdAt).toISOString().split('T')[0];
            if (device.location?.city) {
              acc.registered[date] = (acc.registered[date] || 0) + 1;
            } else {
              acc.notRegistered[date] = (acc.notRegistered[date] || 0) + 1;
            }
            return acc;
          },
          { registered: {}, notRegistered: {} }
        );

        // Group data by TAL, then by city and TA
        const grouped = devices.reduce((acc, device) => {
          const tal = device.location?.tal || 'No TAL';
          const city = device.location?.city || 'No City';
          const ta = device.location?.ta || 'No TA';

          if (!acc[tal]) acc[tal] = {};
          if (!acc[tal][city]) acc[tal][city] = {};
          if (!acc[tal][city][ta]) acc[tal][city][ta] = 0;

          acc[tal][city][ta]++;

          return acc;
        }, {});

        setGroupedData(grouped);

        setChartData({
          totalDevices,
          registeredData: {
            labels: ['Registered', 'Not Registered'],
            datasets: [
              {
                label: 'Registration Status',
                data: [registeredTrue, registeredFalse],
                backgroundColor: ['#74b9ff', '#ff7675'], // Нови цветове
                hoverBackgroundColor: ['#a29bfe', '#fab1a0'], // Цветове при hover
                borderWidth: 1, // Леко очертаване за по-добър ефект
                borderColor: ['#4a148c', '#e65100'], // Очертания с по-тъмен нюанс
              },
            ],
          },
          createdAtData: {
            labels: Object.keys(createdAtCounts),
            datasets: [
              {
                label: 'Devices Registered Per Day',
                data: Object.values(createdAtCounts),
                backgroundColor: '#2196f3',
              },
            ],
          },
          regionData: {
            labels: Object.keys(regionCounts),
            datasets: [
                {
                    data: Object.values(regionCounts),
                    backgroundColor: ['#81ecec', '#74b9ff', '#fab1a0', '#fdcb6e'], // Adjust colors as needed
                },
            ],
        },
          talData: {
            labels: Object.keys(talCounts),
            datasets: [
                {
                    label: 'Devices by TAL',
                    data: Object.values(talCounts),
                    backgroundColor: ['#81ecec', '#74b9ff', '#a29bfe', '#fab1a0'], // Add more colors as needed
                },
            ],
        },
          registrationByDateData: {
            labels: Object.keys(createdAtCounts),
            datasets: [
              {
                label: 'Registered',
                data: Object.keys(createdAtCounts).map((date) => registeredByDate.registered[date] || 0),
                borderColor: '#4caf50',
                backgroundColor: '#4caf50',
                fill: false,
              },
              {
                label: 'Not Registered',
                data: Object.keys(createdAtCounts).map((date) => registeredByDate.notRegistered[date] || 0),
                borderColor: '#f44336',
                backgroundColor: '#f44336',
                fill: false,
              },
            ],
          },
          cityData: {
            labels: Object.keys(cityCounts),
            datasets: [
                {
                    data: Object.values(cityCounts),
                    backgroundColor: ['#81ecec', '#ff7675', '#fab1a0', '#fdcb6e'], // Colors for city pie chart
                },
            ],
        },
        });
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    }
    fetchDevices();

  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Device Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Total Devices</Typography>
            <Typography variant="h2">{chartData?.totalDevices || 0}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Located</Typography>
            <Typography variant="h2">{registeredTrue || 0}</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">On Line Devices</Typography>
            <Typography variant="h2">{onlineDevices}</Typography> {/* Display online devices */}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Registration Status</Typography>
            {chartData && <Pie data={chartData.registeredData} />}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
  <Card style={{ padding: '20px' }}>
    <Typography variant="h6">Devices by Region</Typography>
    {chartData?.regionData && <Pie data={chartData.regionData} />}
  </Card>
</Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Online Devices</Typography>
            {chartData && (
              <Pie
                data={{
                  labels: ['Online', 'Offline'],
                  datasets: [
                    {
                      data: [onlineDevices, chartData.totalDevices - onlineDevices],
                      backgroundColor: ['#74b9ff', '#ff7675'],
                    },
                  ],
                }}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
  <Card style={{ padding: '20px' }}>
    <Typography variant="h6">Devices Registered by TAL</Typography>
    {chartData && <Bar data={chartData.talData} />}
  </Card>
</Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Registration Status by Date</Typography>
            {chartData && <Line data={chartData.registrationByDateData} />}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ padding: '20px' }}>
            <Typography variant="h6">Devices Grouped by TAL</Typography>
            <DevicesTable groupedDevices={groupedData} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;