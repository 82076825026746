import apiClient from './apiClient'; // Импорт на конфигурацията


export const fetchSimulationResults = async (page = 1, limit = 100, filters = {}) => {
  try {
    const response = await apiClient.post(`/simulation`, { page, limit, filters });
    return response.data;
  } catch (error) {
    console.error('Error fetching simulation results:', error);
    throw error;
  }
};

//Devices
export const getActiveDevices = async () => {
  const response = await apiClient.get('/devices');
  return response.data;
};

export const getDevicesCount = async (filters) => {
  console.log('filters: ', filters);
  try {
    const response = await apiClient.post('/pmiDevices/devices/count', filters);
    console.log('Response Device Count from Server: ', response.data.count);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching devices count:', error);
    throw error;
  }
};

export const getPmiDevices = async () => {
  try {
    const response = await apiClient.get('/pmiDevices');
    return response.data; // Адаптирайте отговора според бекенда
  } catch (error) {
    console.error('Error fetching PMI devices:', error);
    throw error;
  }
};
export const getUserDevices = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/collections/dynamic/collection_sheet1_1736411748084', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user devices');
  }
  return response.json();
};

export const createDevice = async (deviceData) => {
  try {
    const response = await apiClient.post('/pmiDevices', deviceData);
    return response.data;
  } catch (error) {
    console.error('Error creating device:', error);
    throw error;
  }
};

export const fetchUniqueValues = async (field) => {
  const response = await apiClient.get(`/pmiDevices/unique-values/${field}`);
  return response.data;
};

export const fetchUsers = async () => {
  const response = await apiClient.get('/contacts/users');
  return response.data;
};
export const addContact = async (contactData) => {
  const response = await apiClient.post('/contacts/create', contactData);
  return response.data;
};

export const updateDevice = async (id, deviceData) => {
  try {
    const response = await apiClient.put(`/pmiDevices/${id}`, deviceData);
    return response.data.device;
  } catch (err) {
    console.error('Error updating device:', err);
    throw err;
  }
};

export const getDeviceCountByCampaign = async (campaignId) => {
  try {
    const response = await apiClient.get(`/campaigns/${campaignId}/device-count`);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching device count:', error);
    throw error;
  }
};
export const getDevicePlaylist = async (serialNumber) => {
  try {
    const response = await apiClient.get(`/devices/playlist/?serialNumber=${serialNumber}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching device playlist:', error);
    throw error;
  }
};

export const fetchDeviceSummary = async (filters) => {
  const response = await apiClient.post('/pmiDevices/devices/summary', filters);
  return response.data;
};

export const addElementsToPlaylist = async (playlistId, elements) => {
  const response = await apiClient.post(`/vxtPlaylists/${playlistId}/add-elements`, { elements });
  return response.data;
};

// Извличане на всички кампании
export const listCampaigns = async () => {
  const response = await apiClient.get('/campaigns');
  return response.data;
};

// Извличане на кампания по ID
export const getCampaignById = async (campaignId) => {
  const response = await apiClient.get(`/campaigns/${campaignId}`);
  return response.data;
};

// Създаване на нова кампания
export const createCampaign = async (campaignData) => {
  const response = await apiClient.post('/campaigns/create', campaignData);
  return response.data;
};

// Обновяване на съществуваща кампания
export const updateCampaign = async (campaignId, campaignData) => {
  console.log('campaignData API to server', campaignData);
  const response = await apiClient.put(`/campaigns/${campaignId}`, campaignData);
  return response.data;
};

// Изтриване на кампания
export const deleteCampaign = async (campaignId) => {
  const response = await apiClient.delete(`/campaigns/${campaignId}`);
  return response.data;
};

// Добавяне на файлове към кампания
export const addFilesToCampaign = async (campaignId, files) => {
  const response = await apiClient.put(`/campaigns/${campaignId}/files`, { files });
  return response.data;
};

export const fetchFileCampaigns = async (fileId) => {
  console.log('fileId: ', fileId);
  const response = await apiClient.get(`/campaigns/file/${fileId}/campaigns`);
  console.log('Response from Server: ', response.data);
  return response.data//await response.json();
};

// Fetch unique PMI Device options
export const fetchTradeSegmentOptions = async () => {
  try {
    const response = await apiClient.get('/pmiDevices/trade-segments/unique');
    return response.data;
  } catch (error) {
    console.error('Error fetching tradeSegment options:', error);
    throw error;
  }
};
export const fetchPosTypetOptions = async () => {
  try {
    const response = await apiClient.get('/pmiDevices/pos-types/unique');
    return response.data;
  } catch (error) {
    console.error('Error fetching pos-types options:', error);
    throw error;
  }
};
export const fetchKaNameOptions = async () => {
  try {
    const response = await apiClient.get('/pmiDevices/kaName/unique');
    console.log('Response from Server kaName: ', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching KA Name options:', error);
    throw error;
  }
};

export const fetchMannedOptions = async () => {
  const response = await apiClient.get('/pmiDevices/manned/unique');
  return response.data;
};

export const fetchPlacementOptions = async () => {
  const response = await apiClient.get('/pmiDevices/placements/unique');
  console.log('Response from Server: ', response.data);
  return response.data;
};


export const fetchTireOptions = async () => {
  const response = await apiClient.get('/pmiDevices/tire/unique');
  return response.data; // Примерен отговор: [{ _id: 1, count: 25 }, { _id: 2, count: 40 }]
};

export const fetchScreeTypeOptions = async () => {
  const response = await apiClient.get('/pmiDevices/screenType/unique');
  return response.data;
};




// Проверка за съществуващи файлове
export const checkFiles = async (fileHashes) => {
  const response = await apiClient.post('/media/check-files', { fileHashes });
  return response.data;
};

// Списък с наличните файлове
export const listFiles = async () => {
  const response = await apiClient.get('/media/list-files');
  console.log('Response from Server: " ',response.data);
  return response.data;
};
export const updateFile = async (fileId, fileData) => {
  try {
    const response = await apiClient.put(`/media/${fileId}`, fileData);
    return response.data;
  } catch (error) {
    console.error('Error updating file:', error);
    throw error;
  }
};
export const deleteFile = async (fileId) => {
  try {
    const response = await apiClient.delete(`/media/${fileId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

export const fetchPlaylists = async () => {
  const response = await apiClient.get('/vxtPlaylists');
  return response.data;
};

export const createPlaylist = async (playlistData) => {
  const response = await apiClient.post('/vxtPlaylists/createPl', playlistData);
  return response.data;
};

export const updatePlaylist = async (playlistId, playlistData) => {
  const response = await apiClient.put(`/vxtPlaylists/${playlistId}`, playlistData);
  return response.data;
};
export const deleteElementFromPlaylist = async (playlistId, elementId) => {
  const response = await apiClient.delete(`/vxtPlaylists/${playlistId}/remove-element`, {
    data: { elementId },
  });
  return response.data.playlist;
};
export const getPlaylistFiles = async (playlistId) => {
  const response = await apiClient.get(`/vxtPlaylists/${playlistId}/files`);
  return response.data.files;
};

export const fetchCategories = async () => {
  const response = await apiClient.get('/category');
  return response.data;
};

export const createCategory = async (categoryData) => {
  const response = await apiClient.post('/category', categoryData);
  return response.data;
};

export const updateCategory = async (categoryId, categoryData) => {
  try {
    const response = await apiClient.put(`/category/${categoryId}`, categoryData);
    return response.data;
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};

export const deleteCategory = async (categoryId) => {
  try {
    const response = await apiClient.delete(`/category/${categoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting category:', error);
    throw error;
  }
};

/*
import axios from 'axios';

export const getActiveDevices = async () => {
  const response = await axios.get(`${API_BASE_URL}/devices`);
  return response.data;
};
export const addElementsToPlaylist = async (playlistId, elements) => {
  const response = await axios.post(
    `${API_BASE_URL}/vxtPlaylists/${playlistId}/add-elements`,
    { elements } // Тялото на заявката
  );
  return response.data;
};
export const listCampaigns = async () => {
  const response = await axios.get(`${API_BASE_URL}/campaigns`);
  return response.data;
};

export const listFiles = async () => {
  const response = await axios.get(`${API_BASE_URL}/media/list-files`);
  return response.data;
};
export const fetchPlaylists = async () => {
  const response = await axios.get(`${API_BASE_URL}/vxtPlaylists`);
  return response.data;
};

*/