import * as XLSX from 'xlsx';

// ТО ДО: да се промени на библиотека, която използва ExcelJS, защото XLSX не поддържа стилове и форматиране на клетките
/**
 * Експортира данни в Excel файл с допълнителни опции.
 * @param {Array} data - Данните, които ще се експортират (масив от обекти).
 * @param {string} fileName - Името на изходния Excel файл.
 * @param {string} sheetName - Името на листа в Excel файла.
 * @param {Object} options - Допълнителни опции за експорт.
 *    - options.addFilters: Добавя филтри към заглавията (по подразбиране false).
 *    - options.addHyperlinks: Добавя хипервръзки към дадено поле (по подразбиране null).
 *    - options.linkField: Полето, което съдържа идентификатора за хипервръзката.
 *    - options.baseUrl: Базовият URL за хипервръзките.
 */
const exportToExcel = (
    data,
    fileName = 'export.xlsx',
    sheetName = 'Sheet1',
    options = { addFilters: false, addHyperlinks: null, linkField: null, baseUrl: '' }
) => {
    if (!data || data.length === 0) {
        alert('No data to export!');
        return;
    }

    // Създаване на работен лист
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Добавяне на хипервръзки, ако са зададени
    if (options.addHyperlinks && options.linkField && options.baseUrl) {
        data.forEach((item, rowIndex) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 1, c: 0 }); // Име в първата колона
            worksheet[cellAddress].l = {
                Target: `${options.baseUrl}/${item[options.linkField]}`,
            };
        });
    }

    // Добавяне на филтри, ако е зададено
    if (options.addFilters) {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        worksheet['!autofilter'] = { ref: XLSX.utils.encode_range(range) };
    }

    // Създаване на работна книга
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Записване на файла
    XLSX.writeFile(workbook, fileName);
};

export default exportToExcel;
