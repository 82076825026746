// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-filters {
    margin-bottom: 20px;
  }
  
  .device-filters label {
    display: inline-block;
    width: 100px;
    font-weight: bold;
  }
  
  .device-filters select {
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  select:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/DeviceFilters.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".device-filters {\n    margin-bottom: 20px;\n  }\n  \n  .device-filters label {\n    display: inline-block;\n    width: 100px;\n    font-weight: bold;\n  }\n  \n  .device-filters select {\n    margin: 5px;\n    padding: 5px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n  }\n  select:disabled {\n    background-color: #f0f0f0;\n    cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
