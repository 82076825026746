import React, { createContext, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';

const MenuAccessContext = createContext();

export const MenuAccessProvider = ({ children }) => {
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : {};
  const menuAccess = decodedToken.menuAccess || [];

  return (
    <MenuAccessContext.Provider value={menuAccess}>
      {children}
    </MenuAccessContext.Provider>
  );
};

export const useMenuAccess = () => {
  return useContext(MenuAccessContext);
};
