import React, { useState, useEffect, useRef } from 'react';
import { fetchPlaylists, listCampaigns, updatePlaylist, createPlaylist,deleteElementFromPlaylist } from '../services/api';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import CampaignFileList from '../components/CampaignFileList';

function PlaylistsDialog() {
  const [playlists, setPlaylists] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [formData, setFormData] = useState(null);
  const fileListRef = useRef(null);

  useEffect(() => {
    fetchPlaylistsData();
    fetchCampaignsData();
  }, []);

  const fetchPlaylistsData = async () => {
    try {
      const playlistsData = await fetchPlaylists();
      setPlaylists(playlistsData);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    }
  };

  const fetchCampaignsData = async () => {
    try {
      const campaignsData = await listCampaigns();
      setCampaigns(campaignsData);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };
/*
  const handleEditPlaylist = (playlist) => {
    setFormData({
      ...playlist,
      elements: playlist.elements || [],
    });
  
    const files = playlist.elements
      .filter((element) => element.type === 'media')
      .map((fileElement) => {
        const matchingFile = selectedFiles.find(
          (file) => file._id === fileElement.id.$oid || file._id === fileElement.id
        );
        return {
          ...fileElement,
          ...matchingFile,
        };
      });
  
    const campaignsList = playlist.elements
      .filter((element) => element.type === 'campaign')
      .map((campaignElement) => {
        const matchingCampaign = campaigns.find(
          (campaign) => campaign._id === campaignElement.id.$oid || campaign._id === campaignElement.id
        );
        return {
          ...campaignElement,
          ...matchingCampaign,
        };
      });
  
    setSelectedFiles(files);
    setSelectedCampaigns(campaignsList);
  
    console.log('Loaded files:', files);
    console.log('Loaded campaigns:', campaignsList);
  };
*/
const handleEditPlaylist = async (playlist) => {
  setFormData({
    ...playlist,
    elements: playlist.elements || [],
  });

  // Визуализация на файлове в плейлистата
  const files = playlist.elements.filter((element) => element.type === 'media');
  const campaignsList = playlist.elements.filter((element) => element.type === 'campaign');

  setSelectedFiles(files);
  setSelectedCampaigns(campaignsList);

  console.log('Loaded playlist elements:', playlist.elements);
};

const handleRemoveElement = async (elementId) => {
  if (!formData || !formData._id) {
    console.error('No playlist selected or missing playlist ID.');
    return;
  }

  try {
    console.log('formData id: ',formData._id ,'Removing element:', elementId);
    const updatedPlaylist = await deleteElementFromPlaylist(formData._id, elementId);
    setFormData((prev) => ({
      ...prev,
      elements: updatedPlaylist.elements,
    }));
    console.log('Element removed successfully:', updatedPlaylist);
  } catch (error) {
    console.error('Error removing element:', error);
  }
};

const handleSave = async () => {
  // Филтриране на премахнати елементи
  const remainingElements = formData.elements.filter((element) => {
    const isFile = element.type === 'media' && selectedFiles.some((file) => file._id === element.id);
    const isCampaign = element.type === 'campaign' && selectedCampaigns.some((campaign) => campaign._id === element.id);
    return isFile || isCampaign;
  });

  // Комбиниране на новоизбрани файлове и кампании с останалите елементи
  const elements = [
    ...remainingElements,
    ...selectedFiles
      .filter((file) => !formData.elements.some((element) => element.id === file._id))
      .map((file) => ({
        type: 'media',
        id: file._id,
        name: file.name || file.originalFileName || 'Unnamed File',
        filename: file.filename || file.fileName || 'unknown_file',
        md5: file.md5 || file.fileHash || 'no_hash',
        url: file.url || '',
        validFrom: file.validFrom,
        validTo: file.validTo,
      })),
    ...selectedCampaigns
      .filter((campaign) => !formData.elements.some((element) => element.id === campaign._id))
      .map((campaign) => ({
        type: 'campaign',
        id: campaign._id,
        name: campaign.name || 'Unnamed Campaign',
        filename: campaign.filename || campaign.name || 'unknown_campaign',
        md5: campaign.md5 || 'no_hash',
      })),
  ];

  const payload = {
    name: formData.name || '',
    description: formData.description || '',
    source: formData.source || 'local',
    playlistId: formData.playlistId || null,
    elements,
  };

  console.log('Payload:', payload);

  try {
    if (formData._id) {
      await updatePlaylist(formData._id, payload);
    } else {
      await createPlaylist(payload);
    }

    // Обновяване на плейлистите и нулиране на избраните елементи
    fetchPlaylistsData();
    setFormData(null);
    setSelectedFiles([]); // Изчистване на избраните файлове
    setSelectedCampaigns([]); // Изчистване на избраните кампании
  } catch (error) {
    console.error('Error saving playlist:', error);
  }
};

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Playlists Management
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          setFormData({
            name: '',
            description: '',
            source: 'local',
            playlistId: '',
            elements: [],
          });
          setSelectedFiles([]);
          setSelectedCampaigns([]);
        }}
        style={{ marginBottom: '20px' }}
      >
        Add New Playlist
      </Button>
  
      <Grid container spacing={2} style={{ height: '80vh' }}>
        {/* Лява колона: Списък с плейлисти, файлове и кампании */}
        <Grid
          item
          xs={12}
          md={6}
          style={{ overflowY: 'auto', borderRight: '1px solid #ccc', paddingRight: '20px' }}
        >
          <Typography variant="h5" gutterBottom>
            Playlists
          </Typography>
          <Grid container spacing={2}>
            {playlists.length > 0 ? (
              playlists.map((playlist) => (
                <Grid item key={playlist._id} xs={12} sm={6} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{playlist.name}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => handleEditPlaylist(playlist)}>
                        Edit
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>No playlists available.</Typography>
            )}
          </Grid>
  
          <Typography variant="h5" style={{ marginTop: '20px' }}>
            Available Files
          </Typography>
          <CampaignFileList
            ref={fileListRef}
            selectedFiles={selectedFiles}
            onSelectFile={(updatedFiles) => {
              console.log('Updated selectedFiles:', updatedFiles);
              setSelectedFiles(updatedFiles);
            }}
          />
          <Typography variant="h6" style={{ marginTop: '1rem' }}>
            Available Campaigns
          </Typography>
          <List>
            {campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <ListItem key={campaign._id} disablePadding>
                  <Checkbox
                    checked={selectedCampaigns.some((c) => c._id === campaign._id)}
                    onChange={(e) => {
                      const updatedCampaigns = e.target.checked
                        ? [...selectedCampaigns, campaign]
                        : selectedCampaigns.filter((c) => c._id !== campaign._id);
                      setSelectedCampaigns(updatedCampaigns);
                    }}
                  />
                  <ListItemText primary={campaign.name} />
                </ListItem>
              ))
            ) : (
              <Typography>No campaigns available.</Typography>
            )}
          </List>
        </Grid>
  
        {/* Дясна колона: Текущи елементи в плейлистата */}
        <Grid
          item
          xs={12}
          md={6}
          style={{ overflowY: 'auto', paddingLeft: '20px', borderLeft: '1px solid #ccc' }}
        >
          {formData ? (
            <div>
              <Typography variant="h5">Current Playlist Elements</Typography>
              <List>
                {formData.elements.map((element) => (
                  <ListItem key={element._id || element.id} disablePadding>
                    <ListItemText
                      primary={`${element.type === 'media' ? 'File: ' : 'Campaign: '} ${element.name}`}
                    />
                    <Button
                      size="small"
                      onClick={() => handleRemoveElement(element._id || element.id)}
                      color="secondary"
                    >
                      Remove
                    </Button>
                  </ListItem>
                ))}
                {formData.elements.length === 0 && (
                  <Typography>No elements in playlist.</Typography>
                )}
              </List>
              <div style={{ marginTop: '20px' }}>
                <TextField
                  label="Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={formData.description || ''}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <Button
                  onClick={() => setFormData(null)}
                  style={{ marginRight: '10px', marginTop: '20px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  style={{ marginTop: '20px' }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <Typography variant="body1">
              No playlist selected. Please select a playlist or click "Add New Playlist".
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default PlaylistsDialog;