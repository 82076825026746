import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, List, ListItem, ListItemText, Checkbox } from '@mui/material';

const AddElements = ({ playlistId, onSuccess }) => {
    const [campaigns, setCampaigns] = useState([]);
    const [media, setMedia] = useState([]);
    const [selectedElements, setSelectedElements] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const campaignsRes = await axios.get('/api/campaigns');
            const mediaRes = await axios.get('/api/media/list-files');
            setCampaigns(campaignsRes.data);
            setMedia(mediaRes.data.files);
        };
        fetchData();
    }, []);

    const toggleSelection = (item, type) => {
        setSelectedElements((prev) => {
            const exists = prev.some((el) => el.id === item._id);
            if (exists) {
                return prev.filter((el) => el.id !== item._id);
            }
            return [...prev, { id: item._id, type }];
        });
    };

    const handleSave = async () => {
        try {
            await axios.post(`/api/playlists/${playlistId}/add-elements`, {
                elements: selectedElements,
            });
            alert('Elements added successfully');
            onSuccess();
        } catch (error) {
            console.error('Error saving elements:', error);
        }
    };

    return (
        <div>
            <h3>Add Elements to Playlist</h3>
            <h4>Campaigns</h4>
            <List>
                {campaigns.map((c) => (
                    <ListItem key={c._id} button onClick={() => toggleSelection(c, 'campaign')}>
                        <Checkbox checked={selectedElements.some((el) => el.id === c._id)} />
                        <ListItemText primary={c.name} />
                    </ListItem>
                ))}
            </List>

            <h4>Media</h4>
            <List>
                {media.map((m) => (
                    <ListItem key={m._id} button onClick={() => toggleSelection(m, 'media')}>
                        <Checkbox checked={selectedElements.some((el) => el.id === m._id)} />
                        <ListItemText primary={m.originalFileName || m.fileName} />
                    </ListItem>
                ))}
            </List>

            <Button variant="contained" color="primary" onClick={handleSave}>
                Save Elements
            </Button>
        </div>
    );
};

export default AddElements;