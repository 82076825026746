import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Grid,
  Card
} from '@mui/material';
import FileList from '../components/FileList';

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [posTypes, setPosTypes] = useState([]);
  const [placements, setPlacements] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]); // Ново състояние за избрани файлове
  const [files, setFiles] = useState([]); // Стейт за файловете

  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    channels: [],
    tradeSegment: [],
    tier: [],
    screenType: [],
    placement: '',
    additionalPlacement: '',
    placementToExclude: '',
    posTypes: [],
    files: [], // Добавено поле за файлове
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [isFileDialogOpen, setFileDialogOpen] = useState(false);

  useEffect(() => {
    fetchCampaigns();
    fetchPosTypes();
    fetchPlacements();
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
        try {
            const response = await axios.get('/api/media/list-files'); // Заявка към API
            const fileList = response.data.files; // Достъпваме масива "files"
            setFiles(fileList || []); // Запазваме масива в стейта
        } catch (error) {
            console.error('Error fetching files:', error);
            setFiles([]); // Нулираме стейта при грешка
        }
    };

    fetchFiles();
}, []);
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('/api/campaigns');
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const fetchPosTypes = async () => {
    try {
      const response = await axios.get('/api/pmiDevices/pos-types/unique');
      setPosTypes(response.data);
    } catch (error) {
      console.error('Error fetching POS Types:', error);
    }
  };

  const fetchPlacements = async () => {
    try {
      const response = await axios.get('/api/pmiDevices/placements/unique');
      setPlacements(response.data);
    } catch (error) {
      console.error('Error fetching placements:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!formData.name || !formData.startDate || !formData.endDate) {
      alert('Name, Start Date, and End Date are required!');
      return;
    }
    try {
      const dataToSave = { ...formData, files: selectedFiles.map((file) => file._id) }; // Добавяме избраните файлове
      console.log('Data to save:', dataToSave);
      if (formData._id) {
        await axios.put(`/api/campaigns/${formData._id}`, dataToSave);
      } else {
        await axios.post('/api/campaigns/create', dataToSave);
      }
      fetchCampaigns();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving campaign:', error);
    }
  };

  // Функция за добавяне на избран файл
  const handleFileSelect = (file) => {
    setSelectedFiles((prevFiles) => {
      // Проверка за дублиране
      if (!prevFiles.some((f) => f._id === file._id)) {
        return [...prevFiles, file];
      }
      return prevFiles; // Връщаме без промяна, ако файлът вече съществува
    });
  };

  const handleFileSelection = (file) => {
    if (file && file._id) {
      setFormData((prev) => ({
        ...prev,
        files: [...(prev.files || []), file],
      }));
    } else {
      console.warn('Invalid file selected:', file);
    }
    setSelectedFiles((prev) => [...prev, file]);
  };

  const handleRemoveFile = (fileId) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file._id !== fileId));
    setFormData((prevFormData) => ({
        ...prevFormData,
        files: prevFormData.files.filter((file) => file._id !== fileId),
    }));
};

  const handleOpenFileDialog = () => {
    setFileDialogOpen(true);
  };

  const handleCloseFileDialog = () => {
    setFileDialogOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/campaigns/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); // Затваря диалога за редактиране
    setFormData({
      name: '',
      startDate: '',
      endDate: '',
      channels: [],
      tradeSegment: [],
      tier: [],
      screenType: [],
      placement: '',
      additionalPlacement: '',
      placementToExclude: '',
      posTypes: [],
      files: [], // Нулира избраните файлове
    });
    setSelectedFiles([]); // Изчиства списъка с избрани файлове
  };
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Campaigns Management
      </Typography>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
        Add New Campaign
      </Button>
      <Table>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign._id}>
              <TableCell>{campaign.name}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormData(campaign);
                    setSelectedFiles(campaign.files || []);
                    setOpenDialog(true);
                  }}
                >
                  Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDelete(campaign._id)} style={{ marginLeft: '10px' }}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Диалог за редактиране на кампания */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Campaign</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={formData.name || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
          {/* Други полета за редактиране */}
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* POS Types */}
          <Typography variant="h6" gutterBottom>POS Types</Typography>
          {posTypes.map((type) => (
            
              <label key={type}>
              <Checkbox
                  checked={formData.posTypes?.includes(type) || false}
                  onChange={(e) => {
                    const updatedPosTypes = e.target.checked
                      ? [...(formData.posTypes || []), type]
                      : (formData.posTypes || []).filter((t) => t !== type);
                    setFormData({ ...formData, posTypes: updatedPosTypes });
                  }}
                />
                {type}
              </label>
            
          ))}
          <TextField
            label="POS Types (comma separated)"
            name="posTypes"
            value={formData.posTypes ? formData.posTypes.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, posTypes: e.target.value.split(',').map((pt) => pt.trim()) })
            }
            fullWidth
            margin="normal"
          />
          {/* Channels */}
          <Typography variant="h6" gutterBottom>Channels</Typography>
          {['IR', 'BR'].map((channel) => (
           
              <label key={channel}>
              <Checkbox
                  checked={formData.channels?.includes(channel) || false} // Уверете се, че няма undefined
                  onChange={(e) => {
                    const updatedChannels = e.target.checked
                      ? [...(formData.channels || []), channel]
                      : (formData.channels || []).filter((ch) => ch !== channel);
                    setFormData({ ...formData, channels: updatedChannels });
                  }}
                />
                {channel}
              </label>
            
          ))}

          {/* Tier */}
          <Typography variant="h6" gutterBottom>Tier</Typography>
          {[1, 2, 3, 4, 5].map((tier) => (
            
              <label key={tier}>
              <Checkbox
                  checked={formData.tier?.includes(tier) || false}
                  onChange={(e) => {
                    const updatedTier = e.target.checked
                      ? [...(formData.tier || []), tier]
                      : (formData.tier || []).filter((t) => t !== tier);
                    setFormData({ ...formData, tier: updatedTier });
                  }}
                />
                {tier}
              </label>
           
          ))}
          {/* Trade Segment */}
          <Typography variant="h6" gutterBottom>Trade Segment</Typography>
          {['General trade', 'KA'].map((segment) => (
           
              <label key={segment}>
              <Checkbox
                  checked={formData.tradeSegment?.includes(segment) || false}
                  onChange={(e) => {
                    const updatedTradeSegment = e.target.checked
                      ? [...(formData.tradeSegment || []), segment]
                      : (formData.tradeSegment || []).filter((seg) => seg !== segment);
                    setFormData({ ...formData, tradeSegment: updatedTradeSegment });
                  }}
                />
                {segment}
              </label>
            
          ))}
          <TextField
            label="Placement (comma separated)"
            name="placement"
            value={formData.placement ? formData.placement.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, placement: e.target.value.split(',').map((pl) => pl.trim()) })
            }
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>Placement</Typography>
          {placements.map((placement) => (
            
              <label key={placement}>
              <Checkbox
                  checked={formData.placement?.includes(placement) || false}
                  onChange={(e) => {
                    const updatedPlacements = e.target.checked
                      ? [...(formData.placement || []), placement]
                      : (formData.placement || []).filter((pl) => pl !== placement);
                    setFormData({ ...formData, placement: updatedPlacements });
                  }}
                />
                {placement}
              </label>
            
          ))}
          <TextField
            label="Additional Placement (comma separated)"
            name="additionalPlacement"
            value={formData.additionalPlacement ? formData.additionalPlacement.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, additionalPlacement: e.target.value.split(',').map((ap) => ap.trim()) })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Placement to Exclude (comma separated)"
            name="placementToExclude"
            value={formData.placementToExclude ? formData.placementToExclude.join(', ') : ''}
            onChange={(e) =>
              setFormData({ ...formData, placementToExclude: e.target.value.split(',').map((pte) => pte.trim()) })
            }
            fullWidth
            margin="normal"
          />
          {/* Screen Type */}
          <Typography variant="h6" gutterBottom>Screen Type</Typography>
          {['Regular screen', 'Selling screen', 'Small cash desk screens'].map((screen) => (
            
              <label key={screen}>
              <Checkbox
                  checked={formData.screenType?.includes(screen) || false}
                  onChange={(e) => {
                    const updatedScreenType = e.target.checked
                      ? [...(formData.screenType || []), screen]
                      : (formData.screenType || []).filter((st) => st !== screen);
                    setFormData({ ...formData, screenType: updatedScreenType });
                  }}
                />
                {screen}
              </label>
           
          ))}
          {/* Бутон за добавяне на файлове */}
          <Button variant="contained" color="primary" onClick={handleOpenFileDialog}>
            Add Files
          </Button>
          {selectedFiles.length > 0 && (
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={file._id || index}>
                  {file.originalFileName || 'Unnamed File'}
                  <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => handleRemoveFile(file._id)}
                        style={{ marginLeft: '10px' }}
                    >
                        Remove
                    </Button>
                </li>
              ))}
            </ul>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Диалог за избор на файлове */}
      <Dialog open={isFileDialogOpen} onClose={handleCloseFileDialog} maxWidth="md" fullWidth>
        <DialogTitle>Select Files</DialogTitle>
        <DialogContent>
          {/* Компонент за избор на файлове */}
          <FileList
            files={files} // Списъкът с файлове, идващ от бекенда
            onFileSelect={handleFileSelection} // Функция за избор на файл
            selectedFiles={selectedFiles} // Вече избраните файлове
          />
          {/* Визуализация на избраните файлове */}
        {selectedFiles.length > 0 && (
            <div style={{ marginTop: '20px' }}>
                <Typography variant="h6">Selected Files:</Typography>
                <ul>
                    {selectedFiles.map((file) => (
                        <li key={file._id}>
                            {file.originalFileName || file.fileName}
                        </li>
                    ))}
                </ul>
            </div>
        )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileDialog}>Cancel</Button>
          <Button onClick={handleCloseFileDialog} color="primary" variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Campaigns;