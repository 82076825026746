import React, { useState, useEffect } from 'react';
import { fetchCategories, createCategory, updateCategory, deleteCategory } from '../services/api';
import { TextField, Button, Select, MenuItem, List, ListItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newType, setNewType] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [editMode, setEditMode] = useState(null);

  useEffect(() => {
    const loadCategories = async () => {
      const fetchedCategories = await fetchCategories();
      setCategories(fetchedCategories);
    };
    loadCategories();
  }, []);

  const handleAddCategory = async () => {
    if (!newCategory) return;

    try {
      const category = await createCategory({
        name: newCategory,
        type: selectedType || newType,
      });
      setCategories([...categories, category]);
      setNewCategory('');
      setNewType('');
      setSelectedType('');
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleUpdateCategory = async (id, name, type) => {
    try {
      const updatedCategory = await updateCategory(id, { name, type });
      setCategories(categories.map(cat => (cat._id === id ? updatedCategory : cat)));
      setEditMode(null);
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteCategory(id);
      setCategories(categories.filter(cat => cat._id !== id));
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div>
      <h2>Manage Categories</h2>
      <TextField
        label="New Category"
        value={newCategory}
        onChange={(e) => setNewCategory(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Select
        value={selectedType}
        onChange={(e) => setSelectedType(e.target.value)}
        displayEmpty
        fullWidth
        margin="normal"
      >
        <MenuItem value="">Select Existing Type</MenuItem>
        {categories.map(cat => (
          <MenuItem key={cat._id} value={cat.type}>{cat.type}</MenuItem>
        ))}
      </Select>
      <TextField
        label="New Type (if not existing)"
        value={newType}
        onChange={(e) => setNewType(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleAddCategory}>
        {editMode ? 'Update Category' : 'Add Category'}
      </Button>
      <List>
        {categories.map(cat => (
          <ListItem key={cat._id}>
            {editMode === cat._id ? (
              <>
                <TextField
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={() => handleUpdateCategory(cat._id, newCategory, cat.type)}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                {cat.name} - {cat.type}
                <IconButton onClick={() => setEditMode(cat._id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteCategory(cat._id)}>
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default CategoryManager;
