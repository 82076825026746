import React, { useState, useEffect, useRef } from 'react';
import { getPublicPath } from '../utils/pathUtils';

const VideoPreviewPlaylist = ({ playlist }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {

    const handleNextMedia = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % playlist.length);
    };

    const currentMedia = playlist[currentIndex];
    const isImage = currentMedia?.fileType?.startsWith('image');

    if (isImage) {
      const timer = setTimeout(handleNextMedia, 5000); // 5 seconds for images
      return () => clearTimeout(timer);
    }
const videoElement = videoRef.current; // Capture ref value
    if (videoElement) {
      videoElement.addEventListener('ended', handleNextMedia);
      return () => {
        if (videoElement) {
          videoElement.removeEventListener('ended', handleNextMedia);
        }
      };
    }
  }, [currentIndex, playlist]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((err) => {
        console.error('Error playing video:', err);
      });
    }
  }, [currentIndex]);

  const currentMedia = playlist[currentIndex];

  if (!currentMedia) {
    return <p>No media available in the playlist.</p>;
  }

  const isImage = currentMedia.fileType?.startsWith('image');
  const isVideo = currentMedia.fileType?.startsWith('video');
  const mediaType = currentMedia.fileType || 'video/mp4';
  const previewPath = getPublicPath(currentMedia.localPath || '');

  return (
    <div style={{ maxWidth: '400px', margin: '20px auto', textAlign: 'center' }}>
      {isImage && (
        <img
          src={previewPath}
          alt={currentMedia.filename}
          style={{ width: '100%', height: 'auto' }}
        />
      )}

      {isVideo && (
        <video
          ref={videoRef}
          controls
          autoPlay
          muted
          style={{ width: '100%', height: 'auto' }}
        >
          <source src={previewPath} type={mediaType} />
          Your browser does not support the video tag.
        </video>
      )}

      {!isImage && !isVideo && <p>Unsupported media type</p>}

      <div style={{ marginTop: '10px' }}>
        <p><strong>Filename:</strong> {currentMedia.filename}</p>
        <p><strong>Valid From:</strong> {currentMedia.validFrom || 'N/A'}</p>
        <p><strong>Valid To:</strong> {currentMedia.validTo || 'N/A'}</p>
        <p><strong>Orientation:</strong> {currentMedia.orientation || 'N/A'}</p>
      </div>
    </div>
  );
};

export default VideoPreviewPlaylist;
