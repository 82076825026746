import React, { useState } from 'react';
import { generateUploadUrl } from '../services/digitalOceanService';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { Typography, Button  } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TextField } from '@mui/material';

// Скрити стилове за input
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadFile = ({ onUploadComplete,context = {}  }) => {
    const [files, setFiles] = useState([]); // Списък с избрани файлове
    const [progresses, setProgresses] = useState({}); // Прогрес за всеки файл
    const [validFrom, setValidFrom] = useState('');
    const [validTo, setValidTo] = useState('');
    const [fileValidity, setFileValidity] = useState({}); // Индивидуални дати за всеки файл

    const handleFileChange = (event) => {
        const selectedFiles = [...event.target.files]; // Запазваме избраните файлове
        setFiles(selectedFiles); // Задаваме файловете в state
        setProgresses({}); // Нулиране на прогреса
    
        // Добавяне на валидност за всеки файл
        const initialFileValidity = {};
        selectedFiles.forEach((file) => {
            initialFileValidity[file.name] = { validFrom: '', validTo: '' };
        });
    
        setFileValidity(initialFileValidity); // Запазваме валидността
    };

    const generateFileHash = async (file) => {
        const arrayBuffer = await file.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        return Array.from(new Uint8Array(hashBuffer)).map(byte => byte.toString(16).padStart(2, '0')).join('');
    };

    const checkExistingFiles = async (fileHashes) => {
        const response = await axios.post('/api/media/check-files', { fileHashes });
        return response.data.existingFiles;
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            alert('Please select at least one file!');
            return;
        }

        try {
            const uploadedFiles = [];
            // Генериране на хешове за избраните файлове
            const fileHashes = await Promise.all(files.map(generateFileHash));
            const existingFiles = await checkExistingFiles(fileHashes);

            // Филтриране на новите файлове
            const newFiles = files.filter(
                (file, index) => !existingFiles.some(existing => existing.fileHash === fileHashes[index])
            );

            if (newFiles.length === 0) {
                alert('All selected files are already uploaded.');
                return;
            }

            const newProgresses = { ...progresses };

            // Качване и запис в базата
            await Promise.all(
                newFiles.map(async (file) => {
                    console.log('Uploading file:', file.name, 'type:', file.type);

                    // Генериране на подписан URL
                    const { url, fileName } = await generateUploadUrl(file.name, file.type);

                    // Качване в DigitalOcean Spaces
                    await axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type,
                            'x-amz-meta-original-filename': encodeURIComponent(file.name),
                            'x-amz-meta-file-hash': await generateFileHash(file), // Добавяне на хеша на файла
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            newProgresses[file.name] = percentCompleted;
                            setProgresses({ ...newProgresses });
                        },
                    });

                    console.log(`File uploaded successfully: ${fileName}`);

                    // Запис в базата
                    const fileHash = await generateFileHash(file);
                    const fileValidFrom = fileValidity[file.name].validFrom || validFrom;
                    const fileValidTo = fileValidity[file.name].validTo || validTo;
                    const baseUrl = url.split('?')[0]; // Remove query parameters
                    const uploadedFile = await axios.post('/api/media/upload-file', {
                        fileName: file.name,
                        fileType: file.type,
                        
                        purpose: 'media', // Или друго предназначение
                        fileSize: file.size,
                        fileHash,
                        url: baseUrl, // Save only the base URL
                        validFrom: fileValidFrom,
                        validTo: fileValidTo,
                        campaignId: context.campaignId || null, // Добавяме ID на кампанията
                    });
                    uploadedFiles.push(uploadedFile.data);
                })
            );
            console.log("Uploaded files:", uploadedFiles);
            onUploadComplete(uploadedFiles); // Връщане на качените файлове
            alert('Files uploaded successfully!');
            
        } catch (error) {
            console.error('Error during file upload:', error);
            alert('Failed to upload one or more files.');
        }
    };
    const handleFileValidityChange = (fileName, field, value) => {
        setFileValidity((prev) => ({
            ...prev,
            [fileName]: { ...prev[fileName], [field]: value }
        }));
    };
    const handleGlobalValidityChange = (field, value) => {
        if (field === 'validFrom') {
            setValidFrom(value);
        } else if (field === 'validTo') {
            setValidTo(value);
        }
    
        // Автоматично задаване на валидност за всички файлове
        setFileValidity((prev) => {
            const updatedValidity = { ...prev };
            Object.keys(updatedValidity).forEach((fileName) => {
                updatedValidity[fileName][field] = value; // Задаваме новата стойност за валидност
            });
            return updatedValidity;
        });
    };
    return (
        <div>
            {/* Заглавие */}
            <Typography variant="h4" gutterBottom>
                Upload Content
            </Typography>
    
            {/* Поле за избор на файлове */}
            <label htmlFor="file-input">
                <VisuallyHiddenInput
                    id="file-input"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                />
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                >
                    Select Files
                </Button>
            </label>
    
            {/* Поле за обща валидност */}
            {files.length > 0 && (
                <div style={{ marginTop: '1rem' }}>
                    <Typography variant="h6">Global Validity (All Files):</Typography>
                    <TextField
                        label="Valid From"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={validFrom}
                        onChange={(e) => handleGlobalValidityChange('validFrom', e.target.value)}
                        style={{ marginRight: '1rem', marginTop: "1rem" }}
                    />
                    <TextField
                        label="Valid To"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={validTo}
                        onChange={(e) => handleGlobalValidityChange('validTo', e.target.value)}
                        style={{ marginTop: "1rem" }}
                    />
                </div>
            )}
    
            {/* Списък с избрани файлове */}
            {files.length > 0 && (
                <div>
                    <Typography variant="h6" style={{ marginTop: '1rem' }}>
                        Selected Files:
                    </Typography>
                    <ul style={{paddingLeft: 0}}>
                        {files.map((file) => (
                            <li key={file.name} style={{ marginBottom: '1rem', display:"flex", alignItems: "center", width: "100%" }}>
                                <div style={{marginRight: "15px", width: "calc(100% - 400px)", maxWidth: "500px"}}>
                                    {/* Име и прогрес на качването */}
                                    {file.name} - {progresses[file.name] || 0}% uploaded                                    
                                    
                                    <LinearProgress variant="determinate" value={progresses[file.name] || 0} />
                                </div>
    
                                {/* Полета за индивидуална валидност */}
                                <div style={{ marginTop: '0.5rem' }}>
                                    <TextField
                                        label="Valid From"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={fileValidity[file.name]?.validFrom || ''}
                                        onChange={(e) =>
                                            handleFileValidityChange(file.name, 'validFrom', e.target.value)
                                        }
                                        style={{ marginRight: '1rem' }}
                                    />
                                    <TextField
                                        label="Valid To"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        value={fileValidity[file.name]?.validTo || ''}
                                        onChange={(e) =>
                                            handleFileValidityChange(file.name, 'validTo', e.target.value)
                                        }
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
    
                    {/* Бутон за качване */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        style={{ marginTop: '1rem' }}
                    >
                        Upload All
                    </Button>
                </div>
            )}
        </div>
    );
};

export default UploadFile;