import React from 'react';
import { Select, MenuItem, Typography } from '@mui/material';

const FileSelector = ({ onSelect, files, value }) => (
  <div>
    <Typography variant="h6">Select a file</Typography>
    <Select
      value={value || ''}
      onChange={(e) => onSelect(e.target.value)}
      fullWidth
    >
      {files.map((file) => (
        <MenuItem key={file._id} value={file._id}>
          {file.originalFileName || file.fileName}
        </MenuItem>
      ))}
    </Select>
  </div>
);

export default FileSelector;
